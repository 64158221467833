import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { getRequest, updateRequest } from '../services';
import FlexBtn from "../Components/Common/FlexBtn";
import ReactDatePicker from '../Components/Common/ReactDatePicker';
import { useTranslation } from "react-i18next";

const EditLessonPlan = ({ onRequestClose, lessonID, fetchData, lessonIndex }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");  // State to track file name
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRequest(`/courses/lesson_plan/${lessonID}/`);
        const data = await res.json();
        setData(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [lessonID]);

  const handleInputChange = (weekIndex, itemIndex, field, value) => {
    const updatedData = { ...data };
    updatedData.week_details[weekIndex][itemIndex][field] = value;
    setData(updatedData);
  };

  const handleFileChange = (weekIndex, itemIndex, e) => {
    const file = e.target.files[0];  // Get the uploaded file
    if (file) {
      setFileName(file.name);  // Store the file name in the state
      handleInputChange(weekIndex, itemIndex, 'ContentUpload', file.name);  // Use the file name in your existing function
    }
  };

  const handleDateChange = (weekIndex, itemIndex, date) => {
    handleInputChange(weekIndex, itemIndex, 'date', date);
  };

  const handleSubmit = async () => {
    try {
      await updateRequest(`/courses/lesson_plan/${lessonID}/`, data);
      onRequestClose();
      fetchData();
    } catch (error) {
      console.error('Error updating lesson plan:', error);
    }
  };

  if (loading) {
    return <div className='d-flex align-items-center justify-content-center py-4'>Loading...</div>;
  }

  return (
    <div className='d-flex flex-column w-100 m-0 p-0'>
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className={`d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader studentProfileHeading ${isArabic === "sa" ? "flex-row-reverse" : ""
            }`}>
            <h4 style={{ color: "#060317" }} className="fw-bold">
              {t('EditLessonPlan')}
            </h4>
            <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
              <X />
            </div>
          </div>
        </div>
      </div>
      <div className='px-3 py-2 editModalLesson'>
        {data && data.week_details.map((week, weekIndex) => (
          <div key={weekIndex} className='d-flex flex-column gap-2'>
            {week[lessonIndex] && (
              <div className={`row mb-3 ${isArabic === "sa" ? "flex-row-reverse" : ""
                }`}>
                <h4 className="fw-bold mb-3">Lecture {lessonIndex + 1}</h4>
                <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("Topic")}</label>
                  <input
                    type="text"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    value={week[lessonIndex].topic}
                    onChange={(e) =>
                      handleInputChange(weekIndex, lessonIndex, 'topic', e.target.value)
                    }
                  />
                </div>
                <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("Date")}</label>
                  <ReactDatePicker
                    placeholder="Select a date"
                    getDate={(name, date) => handleDateChange(weekIndex, lessonIndex, date)}
                    name="date"
                    selectedDate={week[lessonIndex].date}
                  />
                </div>
                <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("Completion")}</label>
                  <input
                    type="text"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    value={week[lessonIndex].completion}
                    onChange={(e) =>
                      handleInputChange(weekIndex, lessonIndex, 'completion', e.target.value)
                    }
                  />
                </div>
                <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("ContentUpload")}</label>
                  <div className={`input-group ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                    <label
                      htmlFor={`contentUpload-${weekIndex}-${lessonIndex}`}
                      className="btn btn-outline-secondary bgColor"
                    >
                      {t("ChooseFile")}
                    </label>
                    <input
                      type="file"
                      id={`contentUpload-${weekIndex}-${lessonIndex}`}
                      className="d-none"
                      onChange={(e) => handleFileChange(weekIndex, lessonIndex, e)}
                    />
                    <input
                      type="text"
                      className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                      value={fileName || t("NoFileChosen")}
                      readOnly
                    />
                  </div>

                  <p>{week[lessonIndex].ContentUpload.replace('http://lms-api.wiserbee.ca/static/course_files/file-', '')}</p>
                </div>
                <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("VideoUpload")}</label>
                  <div className={`input-group ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
                    <label
                      htmlFor={`videoUpload-${weekIndex}-${lessonIndex}`}
                      className="btn btn-outline-secondary bgColor"
                    >
                      {t("ChooseFile")}
                    </label>
                    <input
                      type="file"
                      id={`videoUpload-${weekIndex}-${lessonIndex}`}
                      className="d-none"
                      onChange={(e) => {
                        handleInputChange(weekIndex, lessonIndex, 'VideoUpload', e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                      value={t("NoFileChosen")}  // You can update this if needed to display the video file name.
                      readOnly
                    />
                  </div>
                </div>

                <div className={`col-md-12 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                  <label className="form-label">{t("Details")}</label>
                  <textarea
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    value={week[lessonIndex].details}
                    onChange={(e) =>
                      handleInputChange(weekIndex, lessonIndex, 'details', e.target.value)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="col-md-12 px-4 my-3">
        <div className="d-flex gap-3 justify-content-end">
          <span onClick={onRequestClose}>
            <FlexBtn
              label={t("Cancel")}
              color="#463C77"
              bgColor="#EDEBF1"
            />
          </span>
          <span onClick={handleSubmit}>
            <FlexBtn
              label={t("Update")}
              color="white"
              bgColor="#463C77"
              style={{ fontSize: "14px" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditLessonPlan;
