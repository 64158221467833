import React, { useState, useRef, useEffect } from "react";
import { X } from "lucide-react";
import Counter from "../Components/Common/Counter";
import { updateRequest } from "../services";
import toast, { Toaster } from "react-hot-toast";
 
const UpdateQuestionMOdal = ({ onRequestClose, questions, questionID, choices }) => {
    
 
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();

    const [question, setQuestion] = useState("");
    const [click, setClick] = useState(1);
    const [percentage, setPercentage] = useState(0);
    const refs = [ref1, ref2, ref3, ref4];
     
 

    const arr = choices?.map((choice) => ({
        id: choice.id,
        question: choice.mcquestion,
        marks: choice.marks,
        time: 1, // Initialize time counter
        mcqs: [
            {
                answer: choice.option_1,
                isCorrect: choice.option_1_is_correct,
                isChecked: false
            },
            {
                answer: choice.option_2,
                isCorrect: choice.option_2_is_correct,
                isChecked: false
            },
            {
                answer: choice.option_3,
                isCorrect: choice.option_3_is_correct,
                isChecked: false
            },
            {
                answer: choice.option_4,
                isCorrect: choice.option_4_is_correct,
                isChecked: false
            }
        ]
    }));

    const [options, setOptions] = useState(arr);
    const [questionCounter, setQuestionCounter] = useState(1);
    const [timeCounter, setTimeCounter] = useState(1);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    let dots = [];
    for (let i = 0; i < questions; i++) {
        dots.push(i);
    }

    const handleClickQuestion = (counter, action) => {
        if (counter === "question") {
            if (action === "increment") setQuestionCounter((prev) => prev + 1);
            else if (action === "decrement" && questionCounter > 0) {
                setQuestionCounter((prev) => prev - 1);
            }
        }
    };

    const handleClickTime = (counter, action) => {
        if (counter === "mins") {
            if (action === "increment") setTimeCounter((prev) => prev + 1);
            else if (action === "decrement" && timeCounter > 1) {
                setTimeCounter((prev) => prev - 1);
            }
        }
    };

    const handleOptionSelect = (mcqIndex) => {
    
        const updatedMcqs = options[currentQuestionIndex].mcqs.map((mcq, index) => ({
            ...mcq,
            isChecked: index === mcqIndex,
            isCorrect: index === mcqIndex,  // Mark the selected option as correct
        }));
    
        // Deep copy the current question's options
        const updatedOptions = [...options];
        updatedOptions[currentQuestionIndex] = {
            ...updatedOptions[currentQuestionIndex],  // Ensure immutability
            mcqs: updatedMcqs,
        };
    
        setOptions(updatedOptions);  
 
    };

     
    const handleClickNext = async () => {
        const newMcqs = options[currentQuestionIndex].mcqs;
    
        if (question === "") {
            toast("Please add a question");
            return;
        } else if (newMcqs.some((mcq) => mcq.answer === "")) {
            toast("Please provide all options");
            return;
        } else if (!newMcqs.some((mcq) => mcq.isCorrect)) {
            toast("Please mark at least one option as correct");
            return;
        } else if (questionCounter === 0) {
            toast("Marks should be greater than 0");
            return;
        }
    
        // Create the updated question object
        const updatedQuestion = {
            id: questionID,
            exam_quiz: questionID, // Assuming `questionID` refers to the quiz ID
            mcquestion: question,
            option_1: newMcqs[0].answer,
            option_1_is_correct: newMcqs[0].isCorrect,
            option_2: newMcqs[1].answer,
            option_2_is_correct: newMcqs[1].isCorrect,
            option_3: newMcqs[2].answer,
            option_3_is_correct: newMcqs[2].isCorrect,
            option_4: newMcqs[3].answer,
            option_4_is_correct: newMcqs[3].isCorrect,
            marks: questionCounter,
        };
   
        try {
            // Make the API request for the current question update
            const res = await updateRequest(`/quiz/choice/${options[currentQuestionIndex].id}/`, updatedQuestion);
            if (res.ok) {
                toast.success(`Question ${currentQuestionIndex + 1} updated successfully`);
            } else {
                toast.error("Something went wrong, please try again.");
                return;
            }
        } catch (err) {
            console.log(err);
            toast.error("Failed to update the question.");
            return;
        }
    
        // After successful API call, proceed to next question
        if (currentQuestionIndex >= questions - 1) {
            onRequestClose(); // Close modal if it's the last question
        } else {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setClick((prev) => prev + 1);
        }
    
        // Update progress and reset counters
        setPercentage((prev) => prev + 100 / questions);
        setQuestionCounter(1);
        setTimeCounter(1);
        setQuestion("");
    };
    
    useEffect(() => {
        setPercentage((currentQuestionIndex + 1) * (100 / questions));

        // Reset question text and options when the question index changes
        setQuestion(options[currentQuestionIndex]?.question || "");
    }, [currentQuestionIndex, questions, options]);

    const handleOptionChange = (index, e) => {
        const newMcqs = options[currentQuestionIndex].mcqs.slice();
        newMcqs[index].answer = e.target.value;

        const updatedOptions = [...options];
        updatedOptions[currentQuestionIndex].mcqs = newMcqs;

        setOptions(updatedOptions);
    };


 

    return (
        <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
            <Toaster />
            <div className="row d-flex justify-content-center p-0 m-0">
                <div className="col-md-12 examModalWrapper p-0 m-0">
                    <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
                        <h4 style={{ color: "#060317" }} className="fw-bold">
                            Question Details
                        </h4>
                        <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
                            <X />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row px-4 m-0 gap-5 mt-4">
                <div className="col-md-4 m-0 p-0">
                    <div className="w-100 d-flex flex-column gap-2 justify-content-center">
                        <label>
                            {click}/{questions}
                        </label>
                        <div className="questionBarProgress">
                            {dots.map((_, index) => (
                                <div
                                    className={`dot ${index <= currentQuestionIndex ? "dotWhite" : ""}`}
                                    key={index}
                                ></div>
                            ))}
                            <div className="progressBar" style={{ width: percentage + "%" }}></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>Marks</label>
                        <Counter
                            value={questionCounter}
                            isFullWidth={true}
                            counter="question"
                            handleClick={handleClickQuestion}
                        />
                    </div>
                </div>
                <div className="col-md-3 m-0 p-0">
                    <div className="d-flex gap-3 align-items-center">
                        <label>Time</label>
                        <Counter
                            value={timeCounter}
                            isFullWidth={true}
                            counter="mins"
                            handleClick={handleClickTime}
                        />
                    </div>
                </div>
            </div>
            <div className="row px-4 mt-5">
                <div className="col-md-12 mb-4">
                    <textarea
                        rows={3}
                        placeholder="Please provide question"
                        onChange={(e) => setQuestion(e.target.value)}
                        className="form-control"
                        value={question}
                    ></textarea>
                </div>
                {options[currentQuestionIndex].mcqs.map((mcq, mcqIndex) => (
                    <div className="col-md-6 mb-4" key={mcqIndex}>
                        <div className="input-group">
                            <div className="input-group-text">
                                <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name={`radioGroup-${currentQuestionIndex}`}
                                    checked={mcq.isChecked}
                                    onChange={() => handleOptionSelect(mcqIndex)}
                                />
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Option"
                                ref={refs[mcqIndex]}
                                onChange={(e) => handleOptionChange(mcqIndex, e)}
                                value={mcq.answer}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="row px-4 m-0 mt-3">
                <div className="col-md-12 d-flex justify-content-end">
                    <button className="btn btn-primary px-4 py-2" onClick={handleClickNext}>
                        {currentQuestionIndex >= questions - 1 ? "Finish" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateQuestionMOdal;
