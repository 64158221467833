import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { financialData } from '../../../Constant/charts';

const FinancialChart = () => {
  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Financial</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={financialData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="salaryRevenue" fill="#8884d8" />
          <Bar dataKey="revenue" fill="#82ca9d" />
          <Bar dataKey="badDebt" fill="#FF8042" />
          <Bar dataKey="capacityOccupancy" fill="#FFBB28" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FinancialChart;
