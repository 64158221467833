import React from "react";
 
import WelcomeSection from "../Components/Common/WelcomeSection";
import { useLocation } from "react-router-dom";
import { ParentContexProvider } from "../ContextAPI/ParentContext";
import { useSelector } from "react-redux";
const ParentLayout = ({ children }) => {
  const location = useLocation();
   const auth = useSelector((state)=>state.user);
  const pathName = location.pathname;
  const hideWelcomeSection =
    pathName === "/account-setting" || pathName === "/fees-dues";
   
  const user = auth.user;
  return (
    <ParentContexProvider>
      {hideWelcomeSection ? null : <WelcomeSection user={user} />}

      {children}
    </ParentContexProvider>
  );
};

export default ParentLayout;
