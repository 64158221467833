import React from "react";

const TeacherLearning = () => {
  return (
    <div className="curriculumContent">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut non harum ea.
      Corporis, ut cumque? Porro facilis quaerat velit officiis esse, saepe
      corporis, dolor numquam non doloribus consequatur ipsa ipsum. Lorem ipsum
      dolor sit amet consectetur adipisicing elit. Aut non harum ea. Corporis,
      ut cumque? Porro facilis quaerat velit officiis esse, saepe corporis,
      dolor numquam non doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit
      amet consectetur adipisicing elit. Aut non harum ea. Corporis, ut cumque?
      Porro facilis quaerat velit officiis esse, saepe corporis, dolor numquam
      non doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Aut non harum ea. Corporis, ut cumque? Porro
      facilis quaerat velit officiis esse, saepe corporis, dolor numquam non
      doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit amet consectetur
      adipisicing elit. Aut non harum ea. Corporis, ut cumque? Porro facilis
      quaerat velit officiis esse, saepe corporis, dolor numquam non doloribus
      consequatur ipsa ipsum. Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Aut non harum ea. Corporis, ut cumque? Porro facilis quaerat velit
      officiis esse, saepe corporis, dolor numquam non doloribus consequatur
      ipsa ipsum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
      non harum ea. Corporis, ut cumque? Porro facilis quaerat velit officiis
      esse, saepe corporis, dolor numquam non doloribus consequatur ipsa ipsum.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut non harum ea.
      Corporis, ut cumque? Porro facilis quaerat velit officiis esse, saepe
      corporis, dolor numquam non doloribus consequatur ipsa ipsum. Lorem ipsum
      dolor sit amet consectetur adipisicing elit. Aut non harum ea. Corporis,
      ut cumque? Porro facilis quaerat velit officiis esse, saepe corporis,
      dolor numquam non doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit
      amet consectetur adipisicing elit. Aut non harum ea. Corporis, ut cumque?
      Porro facilis quaerat velit officiis esse, saepe corporis, dolor numquam
      non doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Aut non harum ea. Corporis, ut cumque? Porro
      facilis quaerat velit officiis esse, saepe corporis, dolor numquam non
      doloribus consequatur ipsa ipsum. Lorem ipsum dolor sit amet consectetur
      adipisicing elit. Aut non harum ea. Corporis, ut cumque? Porro facilis
      quaerat velit officiis esse, saepe corporis, dolor numquam non doloribus
      consequatur ipsa ipsum. Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Aut non harum ea. Corporis, ut cumque? Porro facilis quaerat velit
      officiis esse, saepe corporis, dolor numquam non doloribus consequatur
      ipsa ipsum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
      non harum ea. Corporis, ut cumque? Porro facilis quaerat velit officiis
      esse, saepe corporis, dolor numquam non doloribus consequatur ipsa ipsum.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut non harum ea.
      Corporis, ut cumque? Porro facilis quaerat velit officiis esse, saepe
      corporis, dolor numquam non doloribus consequatur ipsa ipsum.
    </div>
  );
};

export default TeacherLearning;
