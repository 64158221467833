import React, { useState, useRef, useEffect } from "react";
import {  X } from "lucide-react";
import Counter from "../Components/Common/Counter";
import { postRequest } from "../services";
import toast, { Toaster } from "react-hot-toast";

const AddQuestions = ({ onRequestClose, questions, questionID }) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  const [question, setQuestion] = useState("");
  const [click, setClick] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const refs = [ref1, ref2, ref3, ref4];
  const [makeApiReq, setMakeApiReq] = useState(false);
  const [count, setCount] = useState(0);

  const [quiz, setQuiz] = useState([]);

  const [options, setOptions] = useState([
    {
      id: 1,
      question: "",
      marks: 0,
      time: 1, // Initialize time counter
      mcqs: [
        {
          answer: "",
          isCorrect: false,
          isChecked: false,
        },
        {
          answer: "",
          isCorrect: false,
          isChecked: false,
        },
        {
          answer: "",
          isCorrect: false,
          isChecked: false,
        },
        {
          answer: "",
          isCorrect: false,
          isChecked: false,
        },
      ],
    },
  ]);
  const [questionCounter, setQuestionCounter] = useState(1);
  const [timeCounter, setTimeCounter] = useState(1); // Time counter state

  let dots = [];
  for (let i = 0; i < questions; i++) {
    dots.push(i);
  }

  const handleClickQuestion = (counter, action) => {
    if (counter === "question") {
      if (action === "increment") setQuestionCounter((prev) => prev + 1);
      else if (action === "decrement" && questionCounter > 0) {
        setQuestionCounter((prev) => prev - 1);
      }
    }
  };

  const handleClickTime = (counter, action) => {
    if (counter === "mins") {
      if (action === "increment") setTimeCounter((prev) => prev + 1); // Increase time
      else if (action === "decrement" && timeCounter > 1) { // Ensure time can't go below 1
        setTimeCounter((prev) => prev - 1); // Decrease time
      }
    }
  };

  const handleOptionSelect = (questionIndex, mcqIndex) => {
    const updatedOptions = options.map((option) => {
      return {
        ...option,
        mcqs: option.mcqs.map((mcq, i) => ({
          ...mcq,
          isChecked: i === mcqIndex,
          isCorrect: i === mcqIndex,
        })),
      };
    });

    setOptions(updatedOptions);
  };

  const handleClickNext = () => {
    const oldOptions = [...options];
    if (click === questions) {
      setMakeApiReq(true);
    }
    const newMcqs = options[0].mcqs;

    if (question === "") {
      toast("Please add a question");
      return;
    } else if (newMcqs.some((mcq) => mcq.answer === "")) {
      toast("Please provide all options");
      return;
    } else if (!newMcqs.some((mcq) => mcq.isCorrect)) {
      toast("Please mark at least one option as correct");
      return;
    } else if (questionCounter === 0) {
      toast("Marks should be greater than 0");
      return;
    }

    const newQuestion = {
      exam_quiz: questionID,
      question: question,
      marks: questionCounter,
      time: timeCounter, // Adding time value to each question
      mcqs: newMcqs,
    };

    setQuiz([...quiz, newQuestion]);
    if (click >= questions) {
      setClick(click);
    } else {
      setClick((prev) => prev + 1);
    }
    setCount(count + 1);
    setPercentage((prev) => prev + 100 / questions);
    setQuestionCounter(1);
    setTimeCounter(1); // Reset time counter after each question
    setOptions(oldOptions);

    setQuestion("");
    refs.forEach((ref) => (ref.current.value = ""));
  };

  useEffect(() => {
    setPercentage((prev) => prev + 100 / questions);
  }, [questions]);

  const handleOptionChange = (index, e) => {
    const newMcqs = options[0].mcqs.slice();

    newMcqs[index].answer = e.target.value;

    const oldOptions = [...options];

    oldOptions[0].mcqs = newMcqs;

    setOptions(oldOptions);
  };

  useEffect(() => {
    async function postQuestion() {
      const format = quiz.map((option) => ({
        exam_quiz: option.exam_quiz,
        marks: option.marks,
        time: option.time, // Time included in the payload
        mcquestion: option.question,
        option_1: option.mcqs[0].answer,
        option_1_is_correct: option.mcqs[0].isCorrect,
        option_2: option.mcqs[1].answer,
        option_2_is_correct: option.mcqs[1].isCorrect,
        option_3: option.mcqs[2].answer,
        option_3_is_correct: option.mcqs[2].isCorrect,
        option_4: option.mcqs[3].answer,
        option_4_is_correct: option.mcqs[3].isCorrect,
      }));
      const payload = {
        mcqs: format,
      };
      if (quiz.length > 0) {
        try {
          const res = await postRequest("/quiz/choice/", payload);
          if (res.ok) {
            toast.success("Questions added successfully");
            onRequestClose();
          } else {
            toast.error("Something went wrong, please try again.");
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  
    if (makeApiReq === true && quiz.length > 0) {
      postQuestion();
      setMakeApiReq(false); // Reset the flag after API call
    }
  }, [makeApiReq, onRequestClose, quiz]);
  

  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <Toaster />
      <div className="row d-flex justify-contents-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">
              Question Details
            </h4>
            <div className="iconWrapper cursor-pointer" onClick={onRequestClose}>
              <X />
            </div>
          </div>
        </div>
      </div>
      <div className="row px-4 m-0 gap-5 mt-4">
        <div className="col-md-4 m-0 p-0">
          <div className="w-100 d-flex flex-column gap-2 justify-content-center">
            <label>
              {click}/{questions}
            </label>
            <div className="questionBarProgress">
              {dots.map((_, index) => (
                <div
                  className={`dot ${index < click ? "dotWhite" : ""}`}
                  key={index}
                ></div>
              ))}
              <div className="progressBar" style={{ width: percentage + "%" }}></div>
            </div>
          </div>
        </div>
        <div className="col-md-3 m-0 p-0">
          <div className="d-flex gap-3 align-items-center">
            <label>Marks</label>
            <Counter
              value={questionCounter}
              isFullWidth={true}
              counter="question"
              handleClick={handleClickQuestion}
              disable={click > questions}
            />
          </div>
        </div>
        <div className="col-md-3 m-0 p-0">
          <div className="d-flex gap-3 align-items-center">
            <label>Time</label>
            <Counter
              value={timeCounter}
              isFullWidth={true}
              counter="mins"
              handleClick={handleClickTime} // Time increment/decrement handler
              disable={click > questions}
            />
          </div>
        </div>
        
      </div>
      <>
        {options.map((option, index) => (
          <div className="row" key={index}>
            <div className="row px-4 mt-5">
              <div className="col-md-12 mb-4">
                <textarea
                  rows={3}
                  placeholder="Please provide question"
                  onChange={(e) => setQuestion(e.target.value)}
                  className="form-control"
                ></textarea>
              </div>
              {option.mcqs.map((mcq, mcqIndex) => (
                <div className="col-md-6 mb-4" key={mcqIndex}>
                  <div className="input-group">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="radio"
                        name={`radioGroup-${index}`}
                        checked={mcq.isChecked}
                        onChange={() => handleOptionSelect(index, mcqIndex)}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Option"
                      ref={refs[mcqIndex]}
                      onChange={(e) => handleOptionChange(mcqIndex, e)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
      <div className="row px-4 m-0 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <button className="btn btn-primary px-4 py-2" onClick={handleClickNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;
