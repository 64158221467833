
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Trash2, Plus } from "lucide-react";
import ParentModal from "../../modals/admin/ParentModal"; // Import the new modal component
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import {
  addParent,
  updateParent,
  deleteParent,
  fetchParents,
} from "../../redux/ParentSlice";
import { Users, UserCheck, UserX, UserPlus, Briefcase } from "lucide-react";

const Parents = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const parents = useSelector((state) => state.parents.parents); // Get parents from Redux state
  const loading = useSelector((state) => state.parents.loading);
  const error = useSelector((state) => state.parents.error);
  // const [parents, setparents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentParentId, setcurrentParentId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [statusFilter, setStatusFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [designationFilter, setDesignationFilter] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    dispatch(fetchParents()); // Dispatch fetchparents action
  }, [dispatch]);

 
  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    let parentData;

    if (isEdit) {
      parentData = {
        ...data,
        id: currentParentId,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : parents.find((t) => t.id === currentParentId).picture,
      };

      dispatch(updateParent(parentData)); // Dispatch updateparent action
      toast.success("Parent updated successfully!");
    } else {
      parentData = {
        ...data,
        id: parents.length + 1,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : null,
      };

      dispatch(addParent(parentData)); // Dispatch addparent action
      toast.success("parent added successfully!");
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const parent = parents.find((parent) => parent.id === id);

    // Prepopulate form fields with the selected parent's data
    setValue("username", parent.username);
    setValue("email", parent.email);
    setValue("phone", parent.phone);
    setValue("password", parent.password);
    setValue("location", parent.location);
    setValue("dob", parent.dob);
    setValue("gender", parent.gender);
 

    

  

  

    setcurrentParentId(id);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleDelete = (id) => {
    dispatch(deleteParent(id)); // Dispatch deleteparent action
    toast.success("Parent deleted successfully!");
  };

  const handleCardClick = (card, index) => {
    setStatusFilter(card.status);
    setCurrentPage(1);
    setSelectedCard(index);
  };

  const uniqueStatuses = Array.from(
    new Set(parents.map((parent) => parent.status))
  );
  const uniqueLocation = Array.from(
    new Set(parents.map((parent) => parent.location))
  );

  const uniqueDesignations = Array.from(
    new Set(parents.map((parent) => parent.designation))
  );

  const filterParents = (parents, query) => {
    if (!query) {
      return parents;
    }
    return parents.filter((parent) => {
      const usernameMatch = parent.username
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = parent.id && parent.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredParents = filterParents(parents, searchQuery);
  const parentsToDisplay = filteredParents.filter((parent) => {
    console.log(statusFilter);
    const matchesStatus = statusFilter ? parent.status === statusFilter : true;
    const matchesLocation = locationFilter
      ? parent.location === locationFilter
      : true;
    const matchesDesignation = designationFilter
      ? parent.designation === designationFilter
      : true;
    return matchesStatus && matchesLocation && matchesDesignation;
  });

  const totalPages = Math.ceil(parents.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentParents = parentsToDisplay.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Grid for parent Cards */}

      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
        <div className="flex items-end lg:col-span-2">
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueLocation} // Pass unique school options
            value={locationFilter} // Current selected school
            onChange={(value) => setLocationFilter(value)} // Handle change
            placeholder="All locations" // Placeholder text
          />
        </div>
        <div>
          <button
            className="flex items-center justify-center w-full bg-[#241763] text-white p-2 rounded space-x-2"
            onClick={() => {
              setIsModalOpen(true);
              setIsEdit(false);
              reset();
            }}
          >
            <Plus className="h-4 w-4" />
            <span> Add Parent </span>
          </button>
        </div>
      </div>

      {/* Modal for Adding/Editing parent */}
      <ParentModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={
          isEdit && parents.find((t) => t.id === currentParentId)?.picture
        }
      />

      {/* Table for parent List */}
      <div className="overflow-x-auto overflow-visible">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>DOB</th>
              <th>location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {currentParents.map((parent, index) => (
              <>
                <tr
                  key={parent.id}
                  className="p-2 py-2 hover:bg-gray-200 space-y-2"
                >
                  <td>
                    <img
                      src={parent.picture}
                      alt="parent Profile"
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                    />
                  </td>
                  <td>{parent.username}</td>
                  <td>{parent.gender}</td>
                  <td>{parent.email}</td>

                  <td className="text-blue-500">{parent.phone}</td>
                  <td>{parent.dob}</td>
                  <td>{parent.location}</td>

                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEdit(parent.id)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDelete(parent.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === i + 1
                  ? "bg-[#241763] text-white"
                  : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => paginate(currentPage + 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Parents;
