import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { postRequest } from "../../services/index";
import { getRequest } from "../../services/index";
import { updateRequest } from "../../services/index";

import { getErrorMessages } from "../../Constant/helpers";
import CustomDropdown from "../../Components/customs/dropdowns/CustomDropdown";
import { CalendarClock } from "lucide-react";

const ClassesModel = ({ currentClass }) => { // Accepting currentClass as a prop
  const [formData, setFormData] = useState({
    standard: "",
    credit_hours: "",
    start_date: "",
    end_date: "",
    section: [],
    class_type: "Online",
    add_subjects: [],
  });

  const [mainInputs, setMainInputs] = useState([]);
  const [subjectChips, setSubjectChips] = useState([]);
  const [sectionChips, setSectionChips] = useState([]);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Effect to set default values for the form when editing
  useEffect(() => {
    if (currentClass) {
      setFormData({
        standard: currentClass.standard,
        start_date: currentClass.start_date,
        end_date: currentClass.end_date,
        section: currentClass.section || [],
        class_type: currentClass.class_type,
        add_subjects: currentClass.add_subjects || [],
      });
      setMainInputs(currentClass.add_subjects.map(subject => ({
        subject: subject.name,
        days: subject.days,
        options: subject.options || [], // Ensure options are set if available
      })));
      setSectionChips(currentClass.section || []);
    }
  }, [currentClass]);

  const removeChip = (chipToRemove, chipType) => {
    if (chipType === "subject")
      setSubjectChips(subjectChips.filter((chip) => chip !== chipToRemove));
    if (chipType === "section")
      setSectionChips(sectionChips.filter((chip) => chip !== chipToRemove));
  };

  const removeLastChip = (chipType) => {
    if (chipType === "subject") setSubjectChips(subjectChips.slice(0, -1));
    if (chipType === "section") setSectionChips(sectionChips.slice(0, -1));
  };

  const addChip = (chip, chipType) => {
    if (chipType === "subject") setSubjectChips([...subjectChips, chip]);
    if (chipType === "section") setSectionChips([...sectionChips, chip]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { standard, class_type, start_date, end_date } = formData;

    const formattedSubjects = mainInputs.map((input) => ({
      name: input.subject,
      days: input.days.map((day) => ({
        day: day.day,
        start_time: day.start_time,
        end_time: day.end_time,
      })),
      options: input.options, // Include selected sections
    }));

    const payload = {
      standard,
      start_date,
      end_date,
      section: sectionChips,
      class_type,
      add_subjects: formattedSubjects,
    };

    try {
      const res = currentClass
        ? await updateRequest(`/api/quiz/classes_data/${currentClass.id}/`, payload) // Update existing class
        : await postRequest("/api/quiz/classes_data/", payload); // Create new class
      
        if (res.ok) {
          toast.success("Class saved successfully");
          
          // Clear form and related states
          setFormData({
              standard: "",
              credit_hours: "",
              start_date: "",
              end_date: "",
              section: [],
              class_type: "Online",
              add_subjects: [],
          });
          setMainInputs([]);
          setSectionChips([]);
          setSubjectChips([]);
      } else {
          const data = await res.json();
          const message = getErrorMessages(data);
          toast.error(message);
      }
    } catch (err) {
      toast.error("Something went wrong, please try again.");
    }
  };

  const handleAddInputs = () => {
    const newInputs = {
      subject: "",
      days: [{ day: "", start_time: "", end_time: "" }],
      options: [], // Add this field to store selected sections
    };
    setMainInputs([...mainInputs, newInputs]);
  };

  const addMoreDays = (index) => {
    const newDays = { day: "", start_time: "", end_time: "" };
    const newItems = [...mainInputs];
    newItems[index].days.push(newDays);
    setMainInputs(newItems);
  };

  const handleChangeMainInputs = (value, name, index, dayIndex = null) => {
    const oldInputs = [...mainInputs];
    if (dayIndex === null) {
      oldInputs[index][name] = value; // Update the subject name or options
    } else {
      oldInputs[index].days[dayIndex][name] = value; // Update the days and times
    }
    setMainInputs(oldInputs);
  };

  const handleRemoveSubject = (index) => {
    const newSubjects = [...mainInputs];
    newSubjects.splice(index, 1);
    setMainInputs(newSubjects);
  };

  const handleRemoveDay = (subjectIndex, dayIndex) => {
    const newSubjects = [...mainInputs];
    newSubjects[subjectIndex].days.splice(dayIndex, 1);
    setMainInputs(newSubjects);
  };

  return (
    <>
      <Toaster />
      <div className="container-fluid   ">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center py-2 fw-bold fs-6">
            Class Information
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="class">Class/Standard</label>
              <input
                type="text"
                className="form-control"
                placeholder="Class"
                name="standard"
                onChange={handleInputChange}
                value={formData.standard}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="class">Class type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="class_type"
                onChange={handleInputChange}
                value={formData.class_type}
              >
                <option>Online</option>
                <option>Physical</option>
                <option>Both</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="class">Sections</label>
              <CustomDropdown
                placeholder="to add sections"
                chips={sectionChips}
                addChip={addChip}
                removeLastChip={removeLastChip}
                setChips={setSectionChips}
                removeChip={removeChip}
                chipType="section"
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="class">Credit Hours</label>
              <input
                type="text"
                className="form-control"
                placeholder="Credit hour"
                name="credit_hours"
                onChange={handleInputChange}
                value={formData.credit_hours}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="text-capitalize">
                Start Session
              </label>
              <input
                type="date"
                name="start_date"
                className="form-control"
                onChange={handleInputChange}
                value={formData.start_date}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="text-capitalize">
                End Session
              </label>
              <input
                type="date"
                name="end_date"
                className="form-control"
                value={formData.end_date}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group mt-4">
              <div className="d-flex justify-content-between">
                <label>Add subject per week</label>
                <button
                  htmlFor="class"
                  className="btn btn-secondary mb-3"
                  onClick={handleAddInputs}
                >
                  Add Subjects
                </button>
              </div>
              <div className="d-flex flex-column gap-2">
                {mainInputs.map((inputs, index) => (
                  <div key={index}>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="subject name"
                        name="subject"
                        value={inputs.subject}
                        onChange={(e) =>
                          handleChangeMainInputs(
                            e.target.value,
                            e.target.name,
                            index
                          )
                        }
                      />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleRemoveSubject(index)}
                      >
                        &#x2715;
                      </button>
                      <CalendarClock
                        height={40}
                        width={40}
                        onClick={() => addMoreDays(index)}
                        className="cursor-pointer"
                      />
                    </div>

                    {inputs.days.map((day, dayIndex) => (
                      <div
                        className="d-flex align-items-center gap-4 px-4 mt-3"
                        key={dayIndex}
                      >
                        <select
                          className="form-select"
                          name="day"
                          value={day.day}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        >
                          {days.map((day, i) => (
                            <option value={day} key={i}>
                              {day}
                            </option>
                          ))}
                        </select>

                        <select
                          className="form-select"
                          name="section"
                          value={day.section || ""}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        >
                          <option value="">Select Section</option>
                          {sectionChips.map((section, i) => (
                            <option value={section} key={i}>
                              {section}
                            </option>
                          ))}
                        </select>

                        <input
                          type="time"
                          className="form-control"
                          name="start_time"
                          value={day.start_time}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        />
                        <label>to</label>
                        <input
                          type="time"
                          className="form-control"
                          name="end_time"
                          value={day.end_time}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        />
                        <button
                          className="btn btn-danger btn-sm ms-2"
                          onClick={() => handleRemoveDay(index, dayIndex)}
                        >
                          &#x2715;
                        </button>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" mt-3 py-4 flex justify-end">
            <div className="form-group mt-4">
              <button
                className="btn bg-main text-white btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}; 

export default ClassesModel;
