import React, { useState, useEffect } from 'react';
import Card from '../../Components/admin/Cards/Cards';
import LineChartComponent from '../../Components/admin/Cards/LineChartComponent';
import AreaChartComponent from '../../Components/admin/Cards/AreaChartComponent';
import AcademicResultsChart from '../../Components/admin/Cards/AcademicResultsChart';
import AttendanceChart from '../../Components/admin/Cards/AttendanceChart';
import TeacherObservationChart from '../../Components/admin/Cards/TeacherObservationChart';
import TeacherSatisfactionChart from '../../Components/admin/Cards/TeacherSatisfactionChart';
import VolumesChart from '../../Components/admin/Cards/VolumesChart';
import FinancialChart from '../../Components/admin/Cards/FinancialChart';
import { chartData } from '../../Constant/charts';
import { cardData } from '../../Constant/cards';
import CustomDropdown from '../../Components/admin/CustomDropdown';

const Dashboard = () => {
  const [campusNames, setCampusNames] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [sectionNames, setSectionNames] = useState([]);
  const [subjectNames, setSubjectNames] = useState([]);

  const [campusFilter, setCampusFilter] = useState('');
  const [classFilter, setClassFilter] = useState('');
  const [sectionFilter, setSectionFilter] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');

  useEffect(() => {
    fetch('/filtersData.json')
      .then((response) => response.json())
      .then((data) => {
        setCampusNames(data.campusNames);
        setClassNames(data.classNames);
        setSectionNames(data.sectionNames);
        setSubjectNames(data.subjectNames);
      })
      .catch((error) => {
        console.error("Error fetching the filter data: ", error);
      });
  }, []);

  return (
    <div className="p-6">

<div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            value={card.value}
            percent={card.percent}
            color={card.color}
            progressValue={card.progressValue}
            increase={card.increase}
          />
        ))}
      </div>
      {/* Filter Section */}
      <div className="flex flex-row justify-between mb-6 w-full space-x-4">
        {/* Campus Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={campusNames}
            value={campusFilter}
            onChange={(value) => setCampusFilter(value)}
            placeholder="All Campuses"
          />
        </div>

        {/* Class Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={classNames}
            value={classFilter}
            onChange={(value) => setClassFilter(value)}
            placeholder="All Classes"
          />
        </div>

        {/* Section Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={sectionNames}
            value={sectionFilter}
            onChange={(value) => setSectionFilter(value)}
            placeholder="All Sections"
          />
        </div>

        {/* Subject Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={subjectNames}
            value={subjectFilter}
            onChange={(value) => setSubjectFilter(value)}
            placeholder="All Subjects"
          />
        </div>
      </div>

    

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
     
        <LineChartComponent data={chartData} />
        <AreaChartComponent data={chartData} />
        <FinancialChart />
        <AcademicResultsChart />
        <TeacherSatisfactionChart />
        <VolumesChart />
        <AttendanceChart  />
        <TeacherObservationChart  />
      </div>
    </div>
  );
};

export default Dashboard;
