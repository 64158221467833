import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchStudents = createAsyncThunk('students/fetchStudents', async () => {
    const response = await axios.get('/dummyStdData.json');
    return response.data;
});

const initialState = {
    students: [],
    loading: false,
    error: null,
};

const studentSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        addStudent: (state, action) => {
            state.students.push(action.payload);
        },
        updateStudent: (state, action) => {
            const index = state.students.findIndex(s => s.id === action.payload.id);
            if(index !== -1){
                state.students[index] = action.payload;
            }
        },
        deleteStudent: (state, action) => {
            state.students = state.students.filter(student => student.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudents.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStudents.fulfilled, (state, action) => {
                state.loading = false;
                state.students = action.payload;
            })
            .addCase(fetchStudents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
        },
});

export const {addStudent, updateStudent, deleteStudent} = studentSlice.actions;

export default studentSlice.reducer;