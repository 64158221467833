import React from "react";
import { useTranslation } from "react-i18next";

const CurriculumNav = ({ swtichLayout, layout }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  
  const navItems = [
    t("OurVision"),
    t("MissionStatement"),
    t("LearnerProfile"),
    t("CurriculumCycle"),
    t("SENHandbook"),
    t("SupportProgram"),
    t("TeacherLearningProgram"),
  ];

  return (
    <div className="w-100 bg-white borderRadius_15 px-2 py-3 curriculumNavWrapper">
      {navItems.map((item, index) => (
        <div
          key={index}
          className={`px-2 py-2 mt-1 ${
            item === layout ? "activecurriculumNavItems" : "curriculumNavItems"
          } ${isArabic ==="sa" ? "text-end": ""}`}
          onClick={() => swtichLayout(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default CurriculumNav;
