export const chats = [
  {
    user: "Jennifer Markus",
    tag: "Parent",
    dp: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHJhbmRvbSUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D",
    lastMessage:
      "Hey! Did you finish Hi-Fi wireframes for flora app design so it could be us then now and may be this one will fix it",
    timestamp: "30 mints ago",
  },
  {
    user: "Mary Fruend",
    tag: "Instructor",
    dp: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cmFuZG9tJTIwcGVvcGxlfGVufDB8fDB8fHww",
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
  {
    user: "John Doe",
    tag: "Student",
    dp: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHJhbmRvbSUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D",
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
  {
    user: "Jennifer Markus",
    tag: "Instructor",
    dp: null,
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
  {
    user: "Jennifer Markus",
    tag: "Parent",
    dp: null,
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
  {
    user: "Jennifer Markus",
    tag: "Student",
    dp: null,
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
  {
    user: "Jennifer Markus",
    tag: "Instructor",
    dp: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHJhbmRvbSUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D",
    lastMessage: "Hey! Did you finish Hi-Fi wireframes for flora app design",
    timestamp: "30 mints ago",
  },
];
