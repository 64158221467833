import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ClassDetails = () => {
 
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const subjectsData = [
    {
      subject: "Communication skills",
      class: "9th",
      section: "A",
    },
    {
      subject: "Product design",
      class: "9th",
      section: "B",
    },
    {
      subject: "Case study trials",
      class: "10th",
      section: "A",
    },
    {
      subject: "Custom Essay questions",
      class: "10th",
      section: "B",
    },
    {
      subject: "Custom MCQ’s questions",
      class: "10th",
      section: "A",
    },
  ];
  return (
    <div className="w-100 py-4 px-4  d-flex flex-column userGenInfoWrapper gap-3">
      <h2 className="heading24px fw-bold text-center">{t("ClassesDetails")}</h2>
        <div className="Salary_card">
          <div className="Salary_Report">
          </div>
          <div className="Table_Card" dir={isArabic === "sa" ? "rtl" : "ltr"}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                fontFamily: "arial, sans-serif",
              }}
            >
              <thead>
                <tr>
                <th className="Salary_Heading">{t("SubjectName")}</th>
                  <th className="Salary_Heading">{t("Class")}</th>
                  <th className="Salary_Heading">{t("Section")}</th>
                </tr>
              </thead>
              <tbody>
                {subjectsData.map((data, index) => (
                  <tr key={index}>
                    <td className="Salary_Data">{data.subject}</td>
                    <td className="Salary_Data">{data.class}</td>
                    <td className="Salary_Data">{data.section}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default ClassDetails;
