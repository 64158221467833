import React from 'react';
import { BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { volumesData } from '../../../Constant/charts';

const STUDENTS = 'student';
const ALL = 'all';



const VolumesChart = ({ filter }) => {
  // Filter data based on selected filter
  const filteredData = volumesData.map(item => {
    if (filter === 'all') {
      return item;
    } else {
      return { name: item.name, [filter]: item[filter] };
    }
  });

  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Volumes</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {filter === ALL || filter === STUDENTS ? <Bar dataKey="student" fill="#8884d8" /> : null}
          {filter === ALL || filter === 'newAdmission' ? <Bar dataKey="newAdmission" fill="#82ca9d" /> : null}
          {filter === ALL || filter === 'dropOut' ? <Bar dataKey="dropOut" fill="#FF8042" /> : null}
          {filter === ALL || filter === 'teachers' ? <Bar dataKey="teachers" fill="#FFBB28" /> : null}
          {filter === ALL && <Line dataKey="turnover" stroke="#000" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VolumesChart;
