import React, { useState, useEffect } from "react";
import { X } from "lucide-react";

const ParentModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture,
}) => {
  const [newPicture, setNewPicture] = useState(null);

  useEffect(() => {
    if (isEdit) {
      setNewPicture(currentPicture);
    } else {
      setNewPicture(null);
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPicture(imageUrl);
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              reset();
              setIsModalOpen(false);
            }}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <span>
              <X />
            </span>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Parent" : "Add Parent"}
        </h2>

        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture}
              alt="Parent Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <h1 className="text-lg font-bold">Personal Details</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("username")}
                required
              />
            </div>
            <div>
              <label className="block">Gender</label>
              <select
                className="w-full p-2 border rounded"
                {...register("gender")}
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div>
              <label className="block">Email</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                {...register("email")}
                required
              />
            </div>
            <div>
              <label className="block">Phone</label>
              <input
                type="tel"
                className="w-full p-2 border rounded"
                {...register("phone")}
                required
              />
            </div>
            <div>
              <label className="block">Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("password")}
                required
              />
            </div>
            <div>
              <label className="block">Confirm Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("confirmPassword")}
                required
              />
            </div>
            <div>
              <label className="block">Location</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("location")}
                required
              />
            </div>
            <div>
              <label className="block">DOB</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("dob")}
                required
              />
            </div>

            <div>
              <label className="block">
                {isEdit ? "Upload New Picture" : "Upload Picture"}
              </label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("picture")}
                required={!isEdit}
                onChange={handlePictureChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-[#241763] text-white rounded"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParentModal;
