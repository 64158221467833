import { createSlice } from "@reduxjs/toolkit";

const session = JSON.parse(localStorage.getItem("user")) || "";


const initialState = {
   user: session.user || ""
}


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
        clearAuth:(state)=>{
            state.user = ""
        }
    }
})

export const {clearAuth} = authSlice.actions;

export default authSlice.reducer;