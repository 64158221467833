import React from "react";

import UserProfile from "../Components/Common/UserProfile";
import UserGenInfo from "../Forms/UserGenInfo";
import ChangePassword from "../Forms/ChangePassword";
import ClassDetails from "../Forms/ClassesDetails";
import { useTranslation } from "react-i18next";


const AccountSettings = () => {
  
  const { i18n } = useTranslation();
  const isArabic = i18n.language;

  return (
    <div className="container-fluid">
      <div className={`row ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
        <div className="col-md-4">
          <UserProfile />
        </div>
        <div className="col-md-8">
          <div className="settings w-100 d-flex flex-column gap-3">
            <UserGenInfo />
            <ClassDetails />
            <ChangePassword />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountSettings;
