import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import Counter from "../Components/Common/Counter";
import { postRequest } from "../services/index";
import { validator } from "../Constant/validator";
import toast, { Toaster } from "react-hot-toast";
import { useUserContext } from "../ContextAPI/useUserContext";
import ReactDatePicker from "../Components/Common/ReactDatePicker";

const AddExamModal = ({ onRequestClose }) => {
  const { userData } = useUserContext();
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [questionCounter, setQuestionCounter] = useState(0);
  const [minsCounter, setMinsCounter] = useState(0);
  const auth = JSON.parse(localStorage.getItem("user"));

  const [examData, setExamData] = useState({
    exam_title: "",
    class: "",
    section: "",
    subject: "",
    category: "Exam",
    mins: 0,
    standard: "Test",
    questions: 0,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
  });
   
  const handleClick = (counter, action) => {
    if (counter === "mins") {
      if (action === "increment") {
        setMinsCounter(prev => prev + 1);
      } else if (minsCounter > 0) {
        setMinsCounter(minsCounter - 1);
      } else setMinsCounter(0);
    } else {
      if (counter === "question") {
        if (action === "increment") setQuestionCounter(prev => prev + 1);
        else if (questionCounter > 0) {
          setQuestionCounter(questionCounter - 1);
        }
      }
    }
  };

  useEffect(() => {
    setExamData(prev => ({
      ...prev,
      questions: questionCounter,
    }));
  }, [questionCounter]);

  useEffect(() => {
    setExamData(prev => ({
      ...prev,
      mins: minsCounter,
    }));
  }, [minsCounter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExamData(prevData => ({ ...prevData, [name]: value }));

    if (name === "class") {
      const sectionsSet = new Set();
      const subjectsSet = new Set();

      userData.combinations.forEach(item => {
        if (item.class === value) {
          if (item.section) sectionsSet.add(item.section);
          if (item.subject) subjectsSet.add(item.subject);
        }
      });

      setSections([...sectionsSet]);
      setSubjects([...subjectsSet]);
      setExamData(prevData => ({ ...prevData, section: "", subject: "" }));
    } else if (name === "section") {
      const subjectsSet = new Set();

      userData.combinations.forEach(item => {
        if (item.class === examData.class && item.section === value) {
          if (item.subject) subjectsSet.add(item.subject);
        }
      });

      setSubjects([...subjectsSet]);
      setExamData(prevData => ({ ...prevData, subject: "" }));
    }
  };

  const handleDateChange = (name, date) => {
    setExamData(prevState => ({
      ...prevState,
      [name]: date,
    }));
  };

  const handleSubmitData = async () => {
    const exam = {
      teacher: auth.userId,
      course: examData.course,
      Exam_title: examData.exam_title,
      standard: examData.standard,
      section: examData.section,
      subject: examData.subject,
      category: examData.category.toLowerCase(),
      number_of_questions: questionCounter,
      duration_in_minutes: examData.mins,
      start_date: examData.start_date,
      end_date: examData.end_date,
      start_time: examData.start_time,
      end_time: examData.end_time,
    };

    const validate = validator(examData);
    if (validate === "success") {
      try {
        const response = await postRequest("/quiz/exam-quizes/", exam);
        const data = await response.json();

        if (response.ok) {
          toast.success("Exam added successfully");
          onRequestClose();
        } else {
          toast.error(data.response.message);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error(validate);
    }
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <Toaster />
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">
              Exam Details
            </h4>
            <div
              className="iconWrapper cursor-pointer"
              onClick={onRequestClose}
            >
              <X />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 p-0 m-0 px-4">
        <div className="col-md-12">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="exam_title">Exam Title</label>
            <input
              type="text"
              id="exam_title"
              name="exam_title"
              className="form-control py-1 fs-6 px-2"
              value={examData.exam_title}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="classSelect">Class</label>
            <select
              className="form-control py-1 fs-6 px-2"
              id="classSelect"
              name="class"
              onChange={handleChange}
              value={examData.class}
            >
              <option value="">Select Class</option>
              {userData.combinations.map((item, index) => (
                <option key={index} value={item.class}>
                  {item.class}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="sectionSelect">Section</label>
            <select
              className="form-control py-1 fs-6 px-2"
              id="sectionSelect"
              name="section"
              onChange={handleChange}
              value={examData.section}
              disabled={!examData.class}
            >
              <option value="">Select Section</option>
              {sections.map((section, index) => (
                <option key={index} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="subjectSelect">Subject</label>
            <select
              className="form-control py-1 fs-6 px-2"
              id="subjectSelect"
              name="subject"
              onChange={handleChange}
              value={examData.subject}
              disabled={!examData.section}
            >
              <option value="">Select Subject</option>
              {subjects.map((subject, index) => (
                <option key={index} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className=" d-flex flex-column gap-2 mt-3">
            <label for="category">Category</label>
            <select
              className="form-control py-1 fs-6 px-2"
              id="category"
              name="category"
              onChange={handleChange}
              value={examData.category}
            >
              <option>Exam</option>
              <option>Assignment</option>
              <option>Practice</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="questions">Number of Questions</label>
            <Counter
              isFullWidth={true}
              handleClick={handleClick}
              counter="question"
              value={questionCounter}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="duration">Duration in Minutes</label>
            <Counter
              isFullWidth={true}
              handleClick={handleClick}
              counter="mins"
              value={minsCounter}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className=" d-flex flex-column gap-2 mt-3">
            <label for="classSelect">Start Date</label>
            <ReactDatePicker
              className="form-control"
              selectedDate={examData.start_date}
              getDate={handleDateChange}
              name="start_date"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className=" d-flex flex-column gap-2 mt-3">
            <label for="sectionSelect">End Date</label>
            <ReactDatePicker
              className="form-control"
              selectedDate={examData.end_date}
              getDate={handleDateChange}
              name="end_date"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="start_time">Start Time</label>
            <input
              type="time"
              id="start_time"
              name="start_time"
              className="form-control py-1 fs-6 px-2"
              value={examData.start_time}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="end_time">End Time</label>
            <input
              type="time"
              id="end_time"
              name="end_time"
              className="form-control py-1 fs-6 px-2"
              value={examData.end_time}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row px-4 py-2 ">
        <div className="col-md-12 d-flex justify-content-center justify-content-md-end">
          <div className="row">
            <div className="col-md-6 mt-2">
              <button
                className="btnFooter"
                style={{ backgroundColor: "#EDEBF1", color: "#463C77" }}
                onClick={onRequestClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-6 mt-2">
              <button
                className="btnFooter"
                style={{ backgroundColor: "#463C77", color: "white" }}
                onClick={handleSubmitData}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExamModal;
