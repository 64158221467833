import React, { useState, useEffect } from "react";
import CurriculumNav from "../../Components/Common/CurriculumNav";
import { useTranslation } from "react-i18next";
import {
  OurVision,
  Mission,
  SEN,
  Support,
  CurriculumCycle,
  TeacherLearning,
  LearnerProfile,
} from "../../Components/Curriculum/index";

const CurriculumDesign = () => {
  const { t, i18n } = useTranslation();

  const [layout, setLayout] = useState("");

  const isArabic = i18n.language;
  useEffect(() => {
    setLayout(t("OurVision"));
  }, [i18n.language]); 

  const layouts = {
    [t("OurVision")]: <OurVision />,
    [t("MissionStatement")]: <Mission />,
    [t("LearnerProfile")]: <LearnerProfile />,
    [t("CurriculumCycle")]: <CurriculumCycle />,
    [t("SENHandbook")]: <SEN />,
    [t("SupportProgram")]: <Support />,
    [t("TeacherLearningProgram")]: <TeacherLearning />,
  };

  return (
    <div className="container-fluid">
      <div className={`row ${isArabic === "sa" ? "flex-row-reverse": ""}`}>
        <div className="col-md-4 mb-3 md-mb-0">
          {/* Pass the translated layout key */}
          <CurriculumNav swtichLayout={setLayout} layout={layout} />
        </div>
        <div className="col-md-8">
          <div className="curriculumContentWrapper w-100 bg-white d-flex flex-column py-4 px-3 borderRadius_15 gap-4">
            <div className={`curriculumHeader d-flex justify-content-between align-items-center ${isArabic === "sa" ? "flex-row-reverse": ""}`}>
              <h6 className="fw-bold p-0 m-0">{layout}</h6>
              <button className="btn-update">{t("FullScreen")}</button>
            </div>
            <div className="">{layouts[layout]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurriculumDesign;
