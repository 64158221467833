import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
// Import the data
import { teacherObservationData } from '../../../Constant/charts';  // Ensure the path is correct

const TeacherObservationChart = ({ filter }) => {
  // Filter data based on selected filter
  const filteredData = filter === 'all'
    ? teacherObservationData
    : teacherObservationData.filter(item => item.name === filter);

  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Teacher Observation</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* Display bars conditionally based on the filter */}
          {filter === 'all' || filter === '1st Term' ? <Bar dataKey="score" fill="#FF8042" /> : null}
          {filter === 'all' || filter === 'Mid Term' ? <Bar dataKey="score" fill="#82ca9d" /> : null}
          {filter === 'all' || filter === 'Final Term' ? <Bar dataKey="score" fill="#8884d8" /> : null}
          {filter === 'all' || filter === 'Overall Results' ? <Bar dataKey="score" fill="#FFBB28" /> : null}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TeacherObservationChart;
