import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { users as usersAnalytics } from "../Constant/users";

// Async thunk for fetching parents
export const fetchParents = createAsyncThunk('parents/fetchParents', async () => {
  const response = await axios.get('/dummyData.json');
  return response.data; // Return the response data to the fulfilled action
});

const initialState = {
  parents: [],
  users: usersAnalytics,
  loading: false,
  error: null,
};

const ParentSlice = createSlice({
  name: 'parents',
  initialState,
  reducers: {
    addParent: (state, action) => {
      state.parents.push(action.payload);
    },
    setUser: (state, action) => {
      state.users = state.users.map((us, index) => ({
        ...us,
        isActive: index === action.payload,
      }));
    },
    updateParent: (state, action) => {
      const index = state.parents.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.parents[index] = action.payload;
      }
    },
    deleteParent: (state, action) => {
      state.parents = state.parents.filter(parent => parent.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParents.pending, (state) => {
        state.loading = true; // Set loading to true while fetching
      })
      .addCase(fetchParents.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false after fetching
        state.parents = action.payload; // Set the parents from the API response
      })
      .addCase(fetchParents.rejected, (state, action) => {
        state.loading = false; // Set loading to false if there's an error
        state.error = action.error.message; // Capture error message
      });
  },
});

export const { addParent, updateParent, deleteParent, setUser } = ParentSlice.actions;

export default ParentSlice.reducer;
