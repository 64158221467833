import React from "react";
import FlexBtn from "../../Components/Common/FlexBtn";
import { importSvg, exportSvg } from "../../Constant/svgs";
import { headings, data } from "../../Constant/grades";
import GradeTable from "../../Components/Common/GradeTable";
import { useTranslation } from "react-i18next";
const GradeManagement = () => {

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;

  return (
    <div className="container-fluid px-2 py-2 shadow-md bg-white rounded">
      <div className={`d-flex justify-content-between ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          <h4 className="fw-bold heading24px">{t("GradebookOverview")}</h4>
          <FlexBtn
              icon={exportSvg}
              label={t("Export")}
              color="white"
              bgColor="#241763"
          />
            {/* <FlexBtn
              icon={importSvg}
              label="Import"
              color="#463C77"
              bgColor="#EDEBF1"
            /> */}
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <GradeTable headings={headings} data={data} />
        </div>
      </div>
    </div>
  );
};

export default GradeManagement;
