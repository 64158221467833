import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import StudentModal from "../../modals/admin/StudentModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Trash2, Plus } from "lucide-react";
import StudentCards from "../../Components/admin/Cards/StudentCards";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import {
  addStudent,
  updateStudent,
  deleteStudent,
  fetchStudents,
} from "../../redux/studentSlice";
import {
  Users,
  UserCheck,
  UserX,
  UserPlus,
  Briefcase,
  Trophy,
  Medal,
} from "lucide-react";
import { values } from "lodash";

const Students = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students.students);
  const loading = useSelector((state) => state.students.loading);
  const error = useSelector((state) => state.students.error);
  //const [students, setStudents] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const [campusFilter, setCampusFilter] = useState("");
  const [standardFilter, setStandardFilter] = useState("");
  const [sectionFilter, setSectionFilter] = useState("");
  const [tileFilter, setTileFilter] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  const [filteredByStatus, setFilteredByStatus] = useState([]);

  useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  const topPerformers = ["A", "A+"];

  const studentcardData = [
    {
      title: "Total Students",
      icon: Users,
      number: students.length || 0,
      status: "",
    },
    {
      title: "Top Performers",
      icon: Trophy,
      number:
        students.filter((s) => topPerformers.includes(s.overall_annual_res))
          .length || 0,
      status: "TP",
    },
    {
      title: "Execellent Attendance",
      icon: Medal,
      number:
        students.filter((s) => parseFloat(s.attendance) >= 90).length || 0,
      status: "EA",
    },
    {
      title: "Fee Defaulters",
      icon: UserX,
      number: students.filter((s) => s.monthly_fee > 500).length || 0,
      status: "FD",
    },
    // {
    //   title: "Employee Discount Beneficiaries",
    //   icon: UserPlus,
    //   number: students.filter((t) => t.emp_kids_disc > 0).length || 0,
    //   status: "ED"
    // },
  ];

  const onSubmit = (data) => {
    let studentData;

    if (isEdit) {
      studentData = {
        ...data,
        id: currentStudentId,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : students.find((t) => t.id === currentStudentId).picture,
      };

      dispatch(updateStudent(studentData));
      toast.success("Students updated successfully!");
    } else {
      studentData = {
        ...data,
        id: students.length + 1,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : null,
      };

      dispatch(addStudent(studentData));
      toast.success("Students added successfully!");
    }
    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const student = students.find((student) => student.id === id);
  
    // Prepopulate form fields with the selected student's data
    const studentData = {
      username: student.username,
      email: student.email,
      phone: student.phone,
      password: student.password,
      location: student.location,
      dob: student.dob,
      gender: student.gender,
      standard: student.standard,
      section: student.section,
      admission_date: student.admission_date,
      father_name: student.father_name,
      father_cnic: student.father_cnic,
      father_contact: student.father_contact,
      father_profession: student.father_profession,
      mother_name: student.mother_name, // Corrected from father_name
      mother_cnic: student.mother_cnic, // Corrected from father_cnic
      mother_contact: student.mother_contact, // Corrected from father_contact
      mother_profession: student.mother_profession, // Corrected from father_profession
      school: student.school,
      classes: student.classes,
      quizes: student.quizes,
      assignment_completion: student.assignment_completion,
      midterm_res: student.midterm_res,
      finalterm_res: student.finalterm_res,
      overall_annual_res: student.overall_annual_res,
      monthly_fee: student.monthly_fee,
      special_disc: student.special_disc,
      sibling_disc: student.sibling_disc,
      emp_kids_disc: student.emp_kids_disc,
      lms_fee: student.lms_fee,
      extra_curricular_fee: student.extra_curricular_fee,
      attendance: student.attendance,
      teachers_observation: student.teachers_observation,
    };
  
    // Set all field values using setValue
    for (const [key, value] of Object.entries(studentData)) {
      setValue(key, value);
    }
  
    setIsEdit(true);
    setIsModalOpen(true);
    setCurrentStudentId(id);
  };
  

  const handleDelete = (id) => {
    dispatch(deleteStudent(id));
    toast.success("Student deleted successfully!");
  };

  const handleStudentCardClick = (card, index) => {
    setTileFilter(card.status); // Update status filter based on the clicked card
    setCurrentPage(1); // Reset to the first page
    setSelectedCard(index);
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const uniqueCampuses = Array.from(
    new Set(students.map((student) => student.school))
  );
  const uniqueStandards = Array.from(
    new Set(students.map((student) => student.standard))
  );

  const uniqueSections = Array.from(
    new Set(students.map((student) => student.section))
  );

  const filterStudents = (students, query) => {
    if (!query) {
      return students;
    }
    return students.filter((student) => {
      const usernameMatch = student.username
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = student.id && student.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredStudents = filterStudents(students, searchQuery);
  const studentsToDisplay = filteredStudents.filter((student) => {
    const matchesCampus = campusFilter ? student.school === campusFilter : true;
    const matchesStandard = standardFilter
      ? student.standard === standardFilter
      : true;
    const matchesSection = sectionFilter
      ? student.section === sectionFilter
      : true;

    var matchesTile = true;
    if (tileFilter === "TP") {
      matchesTile = tileFilter
        ? topPerformers.includes(student.overall_annual_res)
        : true;
    } else if (tileFilter == "EA") {
      matchesTile = tileFilter ? parseFloat(student.attendance) >= 90 : true;
    } else if (tileFilter == "FD") {
      matchesTile = tileFilter ? student.monthly_fee > 500 : true;
    } else if (tileFilter == "ED") {
      matchesTile = tileFilter ? student.emp_kids_disc > 0 : true;
    } else {
      matchesTile = tileFilter ? student : true;
    }
    return matchesCampus && matchesStandard && matchesSection && matchesTile;
  });
  const totalPages = Math.ceil(students.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentStudents = studentsToDisplay.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
        {studentcardData.map((card, index) => (
          <div
            key={index}
            onClick={() => handleStudentCardClick(card, index)}
            className="cursor-pointer"
          >
            <StudentCards
              icon={card.icon}
              title={card.title}
              number={card.number}
              isSelected={selectedCard === index}
              className="truncate cursor-pointer" // Add cursor pointer for better UX
            />
          </div>
        ))}
      </div>

      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-6">
        <div className="flex items-end lg:col-span-2">
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueCampuses} // Pass unique school options
            value={campusFilter} // Current selected school
            onChange={(value) => setCampusFilter(value)} // Handle change
            placeholder="All Campuses" // Placeholder text
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueStandards}
            value={standardFilter}
            onChange={(value) => setStandardFilter(value)} // Handle change)}
            placeholder="All classes"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueSections}
            value={sectionFilter}
            onChange={(value) => setSectionFilter(value)} // Handle change
            placeholder="All sections"
          />
        </div>
        <div>
          <button
            className="flex items-center justify-center w-full bg-[#241763] text-white p-2 rounded space-x-2"
            onClick={() => {
              setIsModalOpen(true);
              setIsEdit(false);
            }}
          >
            <Plus className="h-4 w-4" />
            <span>Add Student</span>
          </button>
        </div>
      </div>

      <StudentModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={
          isEdit && students.find((t) => t.id === currentStudentId)?.picture
        }
      />

      <div className="overflow-x-auto lg:overflow-visible">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Admission Date</th>
              <th>Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {currentStudents.map((student, index) => (
              <>
                <tr
                  key={student.id}
                  className="p-2 py-2 hover:bg-gray-200 space-y-2"
                >
                  <td>
                    <img
                      src={student.picture}
                      alt="student Profile"
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                    />
                  </td>
                  <td>{student.username}</td>
                  <td>{student.gender}</td>
                  <td>{student.email}</td>
                  <td>{student.admission_date}</td>
                  <td className="text-blue-500">{student.phone}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEdit(student.id)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDelete(student.id)}
                    >
                      <Trash2 className="text-[#241763]  w-4 h-4 group-hover:text-white" />
                    </button>
                    <div
                      onClick={() => toggleView(index)}
                      className="cursor-pointer  hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                    >
                      {expandedRowIndex === index ? (
                        <ChevronUp
                          size={16}
                          className="group-hover:text-white"
                        />
                      ) : (
                        <ChevronDown
                          size={16}
                          className="group-hover:text-white"
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRowIndex === index && (
                  <tr>
                    <td colSpan="10">
                      <div className="bg-gray-100 p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {/* Personal Details */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Personal Details
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Location</td>
                                <td>{student.location}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">DOB</td>
                                <td>{student.dob}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Standard</td>
                                <td>{student.standard}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Section</td>
                                <td>{student.section}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Parent Details */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Parent Details
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Father Name</td>
                                <td>{student.father_name}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">CNIC</td>
                                <td>{student.father_cnic}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Contact</td>
                                <td>{student.father_contact}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Profession</td>
                                <td>{student.father_profession}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Mother Name</td>
                                <td>{student.mother_name}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">CNIC</td>
                                <td>{student.mother_cnic}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Contact</td>
                                <td>{student.mother_contact}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Profession</td>
                                <td>{student.mother_profession}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Academic Performance */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Academic Performance
                          </h1>
                          <h2 className="font-semibold text-base text-blue-400 mb-2">
                            Grade Book
                          </h2>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">School</td>
                                <td>{student.school}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Classes</td>
                                <td>{student.classes}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Quizzes</td>
                                <td>{student.quizes}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Assignment Completion
                                </td>
                                <td>{student.assignment_completion}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Mid Term Result
                                </td>
                                <td>{student.midterm_res}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Final Term Result
                                </td>
                                <td>{student.finalterm_res}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Overall Annual Result
                                </td>
                                <td>{student.overall_annual_res}</td>
                              </tr>
                            </tbody>
                          </table>

                          <h2 className="font-semibold text-base text-blue-400 mb-2 mt-2">
                            Fee & Dues Details
                          </h2>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Monthly Fee</td>
                                <td>{student.monthly_fee}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Any Special Discount
                                </td>
                                <td>{student.special_disc}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Sibling Discount
                                </td>
                                <td>{student.sibling_disc}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Employee Kids Discount
                                </td>
                                <td>{student.emp_kids_disc}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">LMS Fee</td>
                                <td>{student.lms_fee}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Extra Curricular Fee
                                </td>
                                <td>{student.extra_curricular_fee}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Behavior & Attendance */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Behavior & Attendance Performance
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Attendance%</td>
                                <td>{student.attendance}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Teachers Observation
                                </td>
                                <td>{student.teachers_observation}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === i + 1
                  ? "bg-[#241763] text-white"
                  : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => paginate(currentPage + 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Students;
