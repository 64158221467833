import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { datePickerSvg } from "../../Constant/svgs";
import "react-datepicker/dist/react-datepicker.css";

const ReactDatePicker = ({ placeholder, getDate, name, selectedDate }) => {
  const [date, setDate] = useState(selectedDate || null);
  const datePickerRef = useRef();

  const handleCalenderOpen = () => {
    datePickerRef.current.setOpen(true);
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split('T')[0] : null; 
    setDate(formattedDate);
    if (getDate) {
      getDate(name, formattedDate);
    }
  };

  return (
    <div className="dateInputWrapper">
      <DatePicker
        ref={datePickerRef}
        selected={date ? new Date(date) : null}
        onChange={(date) => handleDateChange(date)}
        dateFormat="yyyy-MM-dd"
        className="DatePicker"
        placeholderText={placeholder}
      />
      <div className="iconbox" onClick={handleCalenderOpen}>
        {datePickerSvg}
      </div>
    </div>
  );
};

export default ReactDatePicker;
