// this file will server api requests.

const BASE_URL = "https://lms-api.wiserbee.ca";


export const getRequest = async (endpoint) => {
  const response = await fetch(`${BASE_URL}${endpoint}`);
  return response;
};

export const postRequest = async (endPoint, data, isFileUpload = false) => {
  const options = {
    method: "POST",
    body: isFileUpload ? data : JSON.stringify(data),
    headers: isFileUpload ? {} : {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${BASE_URL}${endPoint}`, options);
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    return response;
  } catch (error) {
    console.error("Error during fetch:", error);
    throw error;
  }
};



export const updateRequest = async (endpoint, data) => {
  return await fetch(`${BASE_URL}${endpoint}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};
