import React from "react";
import { courseBook } from "../../Constant/svgs";
import { Link } from "react-router-dom";

const EntrollCourseTiles = (props) => {
  const { heading, price,isArabic,urlLink } = props;

  return (
    <Link to={urlLink} className={`courseTiles ${isArabic === "sa" ? 'flex-row-reverse' : ''} `}>
      <div className="tilesText">
        <h6>{heading}</h6>
        <p>{price}</p>
      </div>
      {courseBook}
    </Link>
  );
};

export default EntrollCourseTiles;
