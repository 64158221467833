import React, { useEffect, useState } from "react";

import { Plus } from "lucide-react";

import useFetch from "../../hooks/UseFetch";
import Modal from "react-modal";
import AddExamModal from "../../modals/AddExamModal";
import ScheduleExam from "../../modals/ScheduleExam";
import AddQuestions from "../../modals/AddQuestions";
import UpdateQuestionModal from "../../modals/UpdateQuestionModal";
import ExamCards from "../../Components/Teacher/ExamCards";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    border: "none",
  },
};

const ExamsManagement = () => {
  const {
    data: examData,
    loading,
    error,
    refetch,
  } = useFetch("/quiz/exam-quizes/");

  const {
    loading: quizLoading,
    error: quizError,
  } = useFetch("/quiz/exam-schedule/");

  const { data: questionData } = useFetch("/quiz/choice/get_list");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [scheduleExamOpen, setScheduleExamOpen] = useState(false);
  const [questionsOpen, setQuestionsOpen] = useState(false);
  const [questionID, setQuestionID] = useState(null);
  const [refetchList, setRefetchList] = useState(false);
  const [openQuestionModal,setOpenQuestionModal] = useState(false);
  const [choices,setChoices] = useState([]);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;

  const handleAddNewExamClick = () => {
    setIsOpen(true);
  };
  const [examId, setExamId] = useState(null);
  const handleClickScheduleExam = (exam) => {
    setExamId(exam);
    setScheduleExamOpen(true);
    setQuestionsOpen(false);
    refetch("/quiz/exam-quizes/");
  };
  const handleClickQuestions = (question, exam) => {
    setNumberOfQuestions(question);
    setQuestionsOpen(true);
    setQuestionID(exam);
  };
 
  const handleUpdateQuestion = (question,id)=>{
      setOpenQuestionModal(true);
      setQuestionID(id)
      setNumberOfQuestions(question)
      console.log(id)
  }


  useEffect(()=>{
    if(refetchList === true)
    refetch("/quiz/choice/get_list/");
 
},[])
  async function closeModal() {
    setIsOpen(false);
    setScheduleExamOpen(false);

    await refetch();
  }
  async function closeQuestionModal() {
    setQuestionsOpen(false);
    setRefetchList(true);
    await refetch("/quiz/exam-quizes/");
  }
  
 const checkMcqsStatus = (examId)=>{
  const arr = [];
  for (let res in questionData) {
    arr.push(questionData[res]);
  }
  const unique = new Set([...arr]);
  
  console.log('uniqye',unique)
    const filteredList = arr.flat().filter((item)=>item.exam_quiz === examId);
     
    setChoices([... new Set(filteredList)])
 }
 console.log('question data is ',questionData)
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AddExamModal onRequestClose={closeModal} />
      </Modal>  
      {/* update question modal */}
      <Modal
        isOpen={openQuestionModal}
        onRequestClose={()=>setOpenQuestionModal(false)}
        style={customStyles}
        contentLabel="Update Modal"
      >
        <UpdateQuestionModal onRequestClose={()=>setOpenQuestionModal(false)} questions={numberOfQuestions}
          questionID={questionID} choices={choices}/>
      </Modal>
      <Modal
        isOpen={scheduleExamOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ScheduleExam onRequestClose={closeModal} examId={examId} />
      </Modal>

      <Modal
        isOpen={questionsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <AddQuestions
          onRequestClose={closeQuestionModal}
          questions={numberOfQuestions}
          questionID={questionID}
        />
      </Modal>
      <div className="container-fluid bg-white rounded px-2 py-2">
        <div className={`row d-flex align-items-center ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          <div className="col-md-12">
            <div className={`d-flex justify-content-between ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
              <h4 className="h1 fw-bold heading24px">{t('MyExam')}</h4>
              <button
                className={`text-capitalize fs-6 gap-3 d-flex justify-content-between align-items-center bgMain ${isArabic === "sa" ? "flex-row-reverse" : " "}`}
                style={{
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: "white",
                  width: "auto",
                  whiteSpace: "nowrap",
                  border: "none",
                }}
                onClick={handleAddNewExamClick}
              >
                <span className="flex align-items-center justify-content-center  fw-4 rounded p-0 addButtonSty">
                  <Plus />
                </span>
                <span>{t("NewExam")}</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`row text-capitalize mt-3 ${isArabic === "sa" ? "flex-row-reverse" : " "}`}>
          {loading && <h2>Loading Exams...</h2>}
          {error && <h4>Error Loading Exams</h4>}
          {examData && examData.length > 0
            ? examData?.map((exam, index) => (
                <ExamCards
                  exam={exam}
                  key={index}
                  handleClickScheduleExam={handleClickScheduleExam}
                  quizLoading={quizLoading}
                  quizError={quizError}
                  handleClickQuestions={handleClickQuestions}
                  refetchLists={refetchList}
                  questionData={questionData}
                  handleUpdateQuestion={handleUpdateQuestion}
                  checkMcqsStatus={checkMcqsStatus}
                />
              ))
            : "Loading..."}
           
        </div>
      </div>
    </>
  );
};

export default ExamsManagement;
