export const observation = [
  {
    id:"1",
    title: "Attentiveness",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"2",
    title: "Follow instructions",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"3",
    title: "Positive contribution to class",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"4",
    title: "Organized work",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"5",
    title: "Works carefully and neat",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"6",
    title: "Complete tasks",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
  {
    id:"7",
    title: "Work willingly and without frustration",
    options: ["Excellent", "Meeting Expectations", "Need Improvement", "Poor"],
  },
];
