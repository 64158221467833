// CardComponent.js
import React from "react";

const TeacherCards = ({ icon: Icon, title, number,  isSelected }) => {
  return (
    <div className={`shadow-md rounded-md p-4 flex items-center justify-between ${
      isSelected ? "border-[0.5px] border-[#241763] bg-blue-100 " : "bg-white"
    }`}>
      <div className="flex flex-col">
        <div className="flex items-center space-x-3">
          <Icon className="w-8 h-8 text-[#241763]" /> {/* Icon as JSX */}
          <h3 className="text-md font-semibold">{title}</h3>
        </div>
        <p className="text-lg font-bold mt-2">{number}</p>
      </div>
    </div>
  );
};

export default TeacherCards;
