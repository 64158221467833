import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Plus, Trash2 } from "lucide-react";
import TeacherModal from "../../modals/admin/TeacherModal"; // Import the new modal component
import TeacherCards from "../../Components/admin/Cards/TeacherCards";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import {
  addTeacher,
  updateTeacher,
  deleteTeacher,
  fetchTeachers,
} from "../../redux/teachersSlice";
import { Users, UserCheck, UserX, UserPlus, Briefcase } from "lucide-react";

const Teachers = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const teachers = useSelector((state) => state.teachers.teachers); // Get teachers from Redux state
  const loading = useSelector((state) => state.teachers.loading);
  const error = useSelector((state) => state.teachers.error);
  // const [teachers, setTeachers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [statusFilter, setStatusFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [designationFilter, setDesignationFilter] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    dispatch(fetchTeachers()); // Dispatch fetchTeachers action
  }, [dispatch]);

  const teacherCardData = [
    {
      title: "Total Teachers",
      icon: Users,
      number: teachers.length || 0,
      status: "",
    }, // Total teachers
    {
      title: "Active",
      icon: UserCheck,
      number: teachers.filter((t) => t.status === "Active").length || 0,
      status: "Active",
    },
    {
      title: "Resigned",
      icon: UserX,
      number: teachers.filter((t) => t.status === "Resigned").length || 0,
      status: "Resigned",
    },
    {
      title: "Unpaid Leaves",
      icon: UserX,
      number: teachers.filter((t) => t.status === "Unpaid Leaves").length || 0,
      status: "Unpaid Leaves",
    },
    {
      title: "Promoted",
      icon: UserPlus,
      number: teachers.filter((t) => t.status === "Promoted").length || 0,
      status: "Promoted",
    },
  ];

  const onSubmit = (data) => {
    let teacherData;

    if (isEdit) {
      teacherData = {
        ...data,
        id: currentTeacherId,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : teachers.find((t) => t.id === currentTeacherId).picture,
      };

      dispatch(updateTeacher(teacherData)); // Dispatch updateTeacher action
      toast.success("Teacher updated successfully!");
    } else {
      teacherData = {
        ...data,
        id: teachers.length + 1,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : null,
      };

      dispatch(addTeacher(teacherData)); // Dispatch addTeacher action
      toast.success("Teacher added successfully!");
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const teacher = teachers.find((teacher) => teacher.id === id);
  
    // Prepopulate form fields with the selected teacher's data
    const teacherData = {
      username: teacher.username,
      email: teacher.email,
      phone: teacher.phone,
      password: teacher.password,
      location: teacher.location,
      dob: teacher.dob,
      gender: teacher.gender,
      joining_date: teacher.joining_date,
      combinations: teacher.combinations,
      job_nature: teacher.job_nature,
      cnic: teacher.cnic,
      status: teacher.status,
      marital_status: teacher.marital_status,
      spouse_name: teacher.spouse_name,
      spouse_cnic: teacher.spouse_cnic,
      kids_details: teacher.kids_details,
      course_level: teacher.course_level,
      class: teacher.class,
      section: teacher.section,
      subject: teacher.subject,
      first_sems_res: teacher.first_sems_res,
      second_sems_res: teacher.second_sems_res,
      third_sems_res: teacher.third_sems_res,
      overall_annual_res: teacher.overall_annual_res,
      attendance: teacher.attendance,
      teachers_observation: teacher.teachers_observation,
      academic: teacher.academic,
      institute: teacher.institute,
      completed_in: teacher.completed_in,
      major_subject: teacher.major_subject,
      cgpa: teacher.cgpa,
      organization_name: teacher.organization_name,
      designation: teacher.designation,
      started_date: teacher.started_date,
      ended_date: teacher.ended_date,
      last_salary: teacher.last_salary,
      main_res: teacher.main_res,
    };
  
    // Set all field values using setValue
    for (const [key, value] of Object.entries(teacherData)) {
      setValue(key, value);
    }
  
    setCurrentTeacherId(id);
    setIsEdit(true);
    setIsModalOpen(true);
  };
  


  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleDelete = (id) => {
    dispatch(deleteTeacher(id)); // Dispatch deleteTeacher action
    toast.success("Teacher deleted successfully!");
  };

  const handleCardClick = (card, index) => {
    setStatusFilter(card.status);
    setCurrentPage(1);
    setSelectedCard(index);
  };

  const uniqueStatuses = Array.from(
    new Set(teachers.map((teacher) => teacher.status))
  );
  const uniqueLocation = Array.from(
    new Set(teachers.map((teacher) => teacher.location))
  );

  const uniqueDesignations = Array.from(
    new Set(teachers.map((teacher) => teacher.designation))
  );

  const filterTeachers = (teachers, query) => {
    if (!query) {
      return teachers;
    }
    return teachers.filter((teacher) => {
      const usernameMatch = teacher.username
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = teacher.id && teacher.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredTeachers = filterTeachers(teachers, searchQuery);
  const teachersToDisplay = filteredTeachers.filter((teacher) => {
    console.log(statusFilter);
    const matchesStatus = statusFilter ? teacher.status === statusFilter : true;
    const matchesLocation = locationFilter
      ? teacher.location === locationFilter
      : true;
    const matchesDesignation = designationFilter
      ? teacher.designation === designationFilter
      : true;
    return matchesStatus && matchesLocation && matchesDesignation;
  });

  const totalPages = Math.ceil(teachers.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentTeachers = teachersToDisplay.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Grid for Teacher Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-4">
        {teacherCardData.map((card, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(card, index)}
            className="cursor-pointer"
          >
            <TeacherCards
              icon={card.icon}
              title={card.title}
              number={card.number}
              isSelected={selectedCard === index}
              className="truncate cursor-pointer"
            />
          </div>
        ))}
      </div>

      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-6">
        <div className="flex items-end lg:col-span-2">
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueStatuses} // Pass unique school options
            value={statusFilter} // Current selected school
            onChange={(value) => setStatusFilter(value)} // Handle change
            placeholder="All Status" // Placeholder text
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueLocation} // Pass unique school options
            value={locationFilter} // Current selected school
            onChange={(value) => setLocationFilter(value)} // Handle change
            placeholder="All locations" // Placeholder text
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueDesignations} // Pass unique school options
            value={designationFilter} // Current selected school
            onChange={(value) => setDesignationFilter(value)} // Handle change
            placeholder="All Designations" // Placeholder text
          />
        </div>
        <div>
          <button
            className="flex items-center justify-center w-full bg-[#241763] text-white p-2 rounded space-x-2"
            onClick={() => {
              setIsModalOpen(true);
              setIsEdit(false);
              reset();
            }}
          >
            <Plus className="h-4 w-4" />
            <span> Add Teacher</span>
          </button>
        </div>
      </div>

      <TeacherModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={isEdit && teachers.find(t => t.id === currentTeacherId)?.picture}
      />

      {/* Table for Teacher List */}
      <div className="overflow-x-auto overflow-visible">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Job Nature</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {currentTeachers.map((teacher, index) => (
              <>
                <tr
                  key={teacher.id}
                  className="p-2 py-2 hover:bg-gray-200 space-y-2"
                >
                  <td>
                    <img
                      src={teacher.picture}
                      alt="Teacher Profile"
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                    />
                  </td>
                  <td>{teacher.username}</td>
                  <td>{teacher.gender}</td>
                  <td>{teacher.email}</td>

                  <td className="text-blue-500">{teacher.phone}</td>
                  <td>{teacher.job_nature}</td>
                  <td>{teacher.status}</td>

                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEdit(teacher.id)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDelete(teacher.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>

                    <div
                      onClick={() => toggleView(index)}
                      className="cursor-pointer  hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                    >
                      {expandedRowIndex === index ? (
                        <ChevronUp
                          size={16}
                          className="group-hover:text-white"
                        />
                      ) : (
                        <ChevronDown
                          size={16}
                          className="group-hover:text-white"
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRowIndex === index && (
                  <tr>
                    <td colSpan="10">
                      <div className="bg-gray-100 p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                        {/* Personal Details Card */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Personal Details
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Location:</td>
                                <td>{teacher.location}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Joining Date:</td>
                                <td>{teacher.joining_date}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">CNIC:</td>
                                <td>{teacher.cnic}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Combinations:</td>
                                <td>{teacher.combinations}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Marital Status:
                                </td>
                                <td>{teacher.marital_status}</td>
                              </tr>
                              {teacher.marital_status === "Married" && (
                                <>
                                  <tr>
                                    <td className="font-semibold">
                                      Spouse Name:
                                    </td>
                                    <td>{teacher.spouse_name}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Spouse CNIC:
                                    </td>
                                    <td>{teacher.spouse_cnic}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Kids Details:
                                    </td>
                                    <td>{teacher.kids_details}</td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* Academics Performance Card */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Academics Performance
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Course Level:</td>
                                <td>{teacher.course_level}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Class:</td>
                                <td>{teacher.class}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Section:</td>
                                <td>{teacher.section}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Subject:</td>
                                <td>{teacher.subject}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  First Sem Result:
                                </td>
                                <td>{teacher.first_sems_res}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Second Sem Result:
                                </td>
                                <td>{teacher.second_sems_res}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Third Sem Result:
                                </td>
                                <td>{teacher.third_sems_res}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Overall Annual Result:
                                </td>
                                <td>{teacher.overall_annual_res}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Behavior Performance Card */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Behavior Performance
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Attendance:</td>
                                <td>{teacher.attendance}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Teacher Observation:
                                </td>
                                <td>{teacher.teachers_observation}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Academics Experience Card */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Academics Experience
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Academic:</td>
                                <td>{teacher.academic}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Institute:</td>
                                <td>{teacher.institute}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Completed In:</td>
                                <td>{teacher.completed_in}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Major Subjects:
                                </td>
                                <td>{teacher.major_subject}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">CGPA:</td>
                                <td>{teacher.cgpa}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Professional Experience Card */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                          <h1 className="font-bold text-base text-blue-400 mb-2">
                            Professional Experience
                          </h1>
                          <table className="w-full">
                            <tbody>
                              <tr>
                                <td className="font-semibold">Organization:</td>
                                <td>{teacher.organization_name}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Designation:</td>
                                <td>{teacher.designation}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">Started:</td>
                                <td>{teacher.started_date}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">End On:</td>
                                <td>{teacher.ended_date}</td>
                              </tr>
                              <tr>
                                <td className="font-semibold">
                                  Main Responsibilities:
                                </td>
                                <td>{teacher.main_res}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === i + 1
                  ? "bg-[#241763] text-white"
                  : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => paginate(currentPage + 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Teachers;
