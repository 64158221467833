import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
 import { UserContextProvider } from "./ContextAPI/useUserContext";
import './i18n';

import { Provider } from "react-redux";
import { store } from "./store/store";
const root = createRoot(document.getElementById("root"));
root.render(
 
    <BrowserRouter>
      <Provider store={store}>
        <UserContextProvider>

        <App />
        </UserContextProvider>
      </Provider>
    </BrowserRouter>
 
);
