 // Card Data
 export const cardData = [
    {
      title: 'Total Students',
      value: '450',
      percent: '45%',
      color: 'bg-green-500',
      increase: '28 Days',
      progressValue: 60,
    },
    {
      title: 'New Students',
      value: '155',
      percent: '90%',
      color: 'bg-yellow-500',
      increase: '28 Days',
      progressValue: 90
    },
    {
      title: 'Total Course',
      value: '52',
      percent: '85%',
      progressValue: 85,
      color: 'bg-blue-500',
      increase: '28 Days',
    },
    {
      title: 'Fees Collection',
      value: '13,921$',
      progressValue: 50,
      percent: '50%',
      color: 'bg-red-500',
      increase: '28 Days',
    },
  ];