import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ClassesModel from "../../modals/admin/ClassesModel";// Modal component for adding/editing class
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pencil, Trash2, ChevronDown, X, Plus } from "lucide-react";
import { getRequest, postRequest, updateRequest } from "../../services/index"; // Import postRequest as well

const ITEMS_PER_PAGE = 8; // Set the number of items to display per page

const Class = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [classes, setClasses] = useState([]);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentClassId, setCurrentClassId] = useState(null);
  const [expandedClassIds, setExpandedClassIds] = useState([]); // State to manage expanded classes
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const [searchQuery, setSearchQuery] = useState(""); // Search query

  // Fetch classes data when the component mounts
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await getRequest("/api/quiz/classes_data/");
        if (response.ok) {
          const data = await response.json();
          setClasses(data);
        } else {
          toast.error("Failed to fetch classes data.");
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
        toast.error("Error fetching classes data.");
      }
    };

    fetchClasses();
  }, []);

  

  // Submit function for class data
  const onSubmit = async (data) => {
    const formattedSubjects = data.add_subjects.map((subject) => ({
      name: subject.subject,
      days: subject.days.map((day) => ({
        day: day.day,
        start_time: day.start_time,
        end_time: day.end_time,
      })),
    }));

    const payload = {
      standard: data.standard,
      class_type: data.class_type,
      start_date: data.start_date,
      end_date: data.end_date,
      section: data.section,
      add_subjects: formattedSubjects,
    };

    if (isEdit && currentClassId) {
      try {
        const updatedClass = await updateRequest(
          `/api/quiz/classes_data/${currentClassId}/`,
          payload
        );
        if (updatedClass.ok) {
          toast.success("Class updated successfully!");
          setClasses(
            classes.map((cls) =>
              cls.id === currentClassId ? { ...cls, ...payload } : cls
            )
          );
        } else {
          toast.error("Failed to update class.");
        }
      } catch (error) {
        toast.error("Error updating class.");
      }
    } else {
      try {
        const newClass = await postRequest("/api/quiz/classes_data/", payload);
        if (newClass.ok) {
          toast.success("Class added successfully!");
          const createdClass = await newClass.json();
          setClasses((prev) => [...prev, createdClass]);
        } else {
          toast.error("Failed to add class.");
        }
      } catch (error) {
        toast.error("Error adding class.");
      }
    }
    reset();
    setIsClassModalOpen(false);
    setIsEdit(false);
  };

  // Function to edit a class
  const handleEdit = (cls) => {
    setValue("standard", cls.standard);
    setValue("class_type", cls.class_type);
    setValue("start_date", cls.start_date);
    setValue("end_date", cls.end_date);
    setValue("section", cls.section);
    setValue("add_subjects", cls.add_subjects);

    setIsEdit(true);
    setCurrentClassId(cls.id);
    setIsClassModalOpen(true);
  };

  // Function to delete a class
  const handleDelete = async (id) => {
    const updatedClasses = classes.filter((cls) => cls.id !== id);
    setClasses(updatedClasses);
    toast.success("Class deleted successfully!");
  };

  // Function to toggle expanded class details
  const toggleExpand = (id) => {
    setExpandedClassIds((prev) =>
      prev.includes(id)
        ? prev.filter((classId) => classId !== id)
        : [...prev, id]
    );
  };

  // Search function
  const filterClasses = (classes, query) => {
    if (!query) return classes;
    return classes.filter((cls) => {
      const standardMatch = cls.standard
        .toLowerCase()
        .includes(query.toLowerCase());
      const subjectsMatch = cls.add_subjects.some((subject) =>
        subject.name.toLowerCase().includes(query.toLowerCase())
      );
      return standardMatch || subjectsMatch;
    });
  };

  const filteredClasses = filterClasses(classes, searchQuery);

  // Calculate pagination
  const totalPages = Math.ceil(filteredClasses.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentClasses = filteredClasses.slice(startIndex, endIndex); // Sliced classes for current page

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <h2 className="text-lg font-semibold">
        Total Classes: {filteredClasses.length}
      </h2>

      <div className="flex justify-between mb-4 space-x-4">
        <input
          type="text"
          placeholder="Search by Class Standard or Subject"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1); // Reset to the first page when searching
          }}
          className="w-3/4 p-2 border rounded"
        />
        <button
          className="flex items-center justify-center w-1/4 bg-[#241763] text-white p-2 rounded space-x-2"
          onClick={() => {
            setIsClassModalOpen(true);
            setIsEdit(false);
          }}
        >
          <Plus className="h-4 w-4" />
          <span>Add Class</span>
        </button>
      </div>

      {isClassModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-end">
              <button
                className=" bg-red-500 text-white rounded"
                onClick={() => setIsClassModalOpen(false)}
              >
                <span>
                  <X />
                </span>
              </button>
            </div>
            <ClassesModel 
              currentClass={
                isEdit ? classes.find((cls) => cls.id === currentClassId) : null
              }
              onSubmit={onSubmit}
              isEdit={isEdit}
            />
          </div>
        </div>
      )}

      <table className="table-auto w-full mt-6">
        <thead className="py-4 border-b font-bold">
          <tr>
            <th>Class Standard</th>
            <th>Class Type</th>
            <th>Subjects</th>
            <th>Credit Hours</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {currentClasses.map((cls) => (
            <React.Fragment key={cls.id}>
              <tr className="p-2 hover:bg-gray-200">
                <td>{cls.standard}</td>
                <td>{cls.class_type}</td>
                <td>
                  {cls.add_subjects.map((subject) => subject.name).join(", ")}
                </td>
                <td>{}</td>
                <td>{cls.start_date}</td>
                <td>{cls.end_date}</td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => handleEdit(cls)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(cls.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => toggleExpand(cls.id)}
                  >
                    <ChevronDown
                      className={`w-4 h-4  group-hover:text-white transition-transform ${
                        expandedClassIds.includes(cls.id) ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                </td>
              </tr>
              {expandedClassIds.includes(cls.id) && (
                <tr className="bg-gray-100">
                  <td colSpan="6" className="p-4">
                    <h4 className="font-bold">Subjects Details:</h4>
                    <ul>
                      {cls.add_subjects.map((subject, index) => (
                        <li key={index}>
                          <strong>{subject.name}</strong>
                          <ul>
                            {subject.days.map((day, dayIndex) => (
                              <li key={dayIndex}>
                                {day.day} | {day.start_time} - {day.end_time}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === i + 1
                  ? "bg-[#241763] text-white"
                  : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Class;
