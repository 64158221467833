import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import { observation } from "../../Constant/observation";

const ParentSurvey = () => {
  const [tab, setTab] = useState("survey");
  const [buttons, setButtons] = useState([
    { label: "teacher", clicked: true },
    { label: "class", clicked: false },
    { label: "school", clicked: false },
  ]);

  const [selectedTeacher, setSelectedTeacher] = useState("");
  const teacherList = ["John", "Smith", "Alex"];

  const handleOptionSelect = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const handleClick = (index) => {
    const newBtns = buttons.map((btn, idx) => ({
      ...btn,
      clicked: idx === index,
    }));
    setButtons(newBtns);
  };

  const activeBtn = buttons.find((btn) => btn.clicked === true);

  return (
    <div className="d-flex flex-column gap-4 mt-3">
      <div className="d-flex align-items-center gap-3">
        <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "survey" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("survey")}>Survey</button>
        <button className={`text-capitalize btn btn-sm py-2 px-3 ${tab === "feedback" ? "btn-primary" : "btn-secondary stylesUnSelcBtn"}`} onClick={() => setTab("feedback")}>Feedback</button>

      </div>
      {tab === "feedback" && <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-3 my-3">
          {buttons.map((btn, index) => (
            <button
              className={`text-capitalize btn btn-sm py-2 px-3 ${btn.clicked ? "btn-primary" : "btn-secondary stylesUnSelcBtn"
                }`}
              key={index}
              onClick={() => handleClick(index)}
            >
              {btn.label}
            </button>
          ))}
        </div>

        {/* Dropdown for Teacher List when Teacher tab is active */}
        {activeBtn.label === "teacher" && (
          <div className="col-md-4 text-start">
            <label htmlFor="teacherInput" className="weakform-text form-label">
              Select Teacher
            </label>
            <div className="mb-3 d-flex">
              <input
                id="teacherInput"
                type="text"
                className="input-cx rounded-start ps-2"
                value={selectedTeacher} // Display the selected teacher
                readOnly
              />
              <button
                className="drop-btn rounded-end"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <ChevronDown className="icon-drop" />
              </button>
              <ul className="dropdown-menu">
                {teacherList.map((teacher, index) => (
                  <li key={index}>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleOptionSelect(teacher)}
                    >
                      {teacher}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <textarea
          rows={4}
          className="form-control"
          placeholder={`Submit feedback for ${activeBtn.label}`}
        ></textarea>

        <p className="d-flex justify-content-end">
          <button className="bg-main text-white btn btn-sm w-25 btn-primary">
            Submit
          </button>
        </p>
      </div>}

      {tab === "survey" && <div className="row px-3 observationContent">
        <div className="col-md-12 px-3 py-3">
          {observation.map((ob, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-md-3">
                <label className="observationText fw-bold">{ob.title}</label>
              </div>
              <div className="col-md-9">
                <div className="observationOptions d-flex flex-column gap-2 justify-content-center">
                  <div className="options d-flex gap-2 align-items-center">
                    {ob.options.map((op, i) => (
                      <div
                        className="form-check align-items-center d-flex gap-2"
                        key={i}
                      >
                        <input
                          className="form-check-input reviewForm"
                          type="radio"
                          name={`observation-${index}`} // Unique name for each observation
                          id={`observation-${index}-${i}`}
                        />
                        <label
                          className="form-check-label formTeacherRep"
                          htmlFor={`observation-${index}-${i}`}
                        >
                          {op}
                        </label>
                      </div>
                    ))}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write your comments here"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="d-flex justify-content-end">
          <button className="btn btn-sm btn-secondary bg-main w-25">Submit</button>
        </p>
      </div>}
    </div>
  );
};

export default ParentSurvey;
