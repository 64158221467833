import React from "react";

const Card = ({ title, value, percent, color, increase, progressValue }) => {
  return (
    <div className={`p-4 rounded-lg shadow ${color} text-white space-y-2`}>
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-4xl font-bold">{value}</p>
      <div className="relative w-full h-[2px] bg-gray-200">
        <progress
          value={progressValue}
          max="100"
          className="absolute top-0 h-[2px] w-full appearance-none"
        />
        <div
          className="absolute top-0 h-[2px] bg-white"
          style={{ width: `${progressValue}%` }}
        />
      </div>

      <p className="mt-2 text-sm">
        {percent} Increase in {increase}
      </p>
    </div>
  );
};

export default Card;
