import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userLeacture, setUserLeacture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth, setAuth] = useState(() => JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const auth = JSON.parse(localStorage.getItem("user"));
        if (auth && (auth.user === "teacher")) {
          const res = await fetch(`https://lms-api.wiserbee.ca/teacher_admin/teacheradmin/${auth.userId}`);
          const data = await res.json();
          console.log("user id data", data);
          if (res.ok){
            setUserData(data);
          }
          else{
            throw new Error("Failed to fetch user data");
          }
          
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchLectureData = async () => {
      try {
        const auth = JSON.parse(localStorage.getItem("user"));
        if (auth && (auth.user === "teacher")) {
          const res = await fetch(`https://lms-api.wiserbee.ca/api/quiz/classes_data/`);
          const data = await res.json();
           
          if (res.ok){
            setUserLeacture(data);
          }
          else{
            throw new Error("Failed to fetch user data");
          }
          
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    fetchLectureData();
  }, [auth]);

  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setAuth(user);
  };
    
  const value = { userData, loading, error,userLeacture ,login};

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
