import { configureStore } from "@reduxjs/toolkit";
import i18n from "../i18n";

import parentReducer from "../redux/ParentSlice";
import toggleSlice from "../redux/ToggleSlice";
import userSlice from "../redux/UserSlice";
import languageSlice from "../redux/languageSlice";
import adminCredSlice from "../redux/adminCredSlice";
import authSlice from "../redux/AuthSlice";
import teachersSlice from "../redux/teachersSlice";
import studentSlice from "../redux/studentSlice";

export const store = configureStore({
  reducer: {
    toggler: toggleSlice,
    user: userSlice,
    language: languageSlice,
    admincredslice: adminCredSlice,
    auth: authSlice,
    teachers: teachersSlice,
    students: studentSlice,
    parents: parentReducer,
  },
});

let previousLanguageCode = store.getState().language.code;

store.subscribe(() => {
  const state = store.getState();
  const currentLanguageCode = state.language.code;

  if (previousLanguageCode !== currentLanguageCode) {
    i18n.changeLanguage(currentLanguageCode);
    previousLanguageCode = currentLanguageCode; 
  }
});
