import React, { useState, useEffect } from "react";
import { X } from "lucide-react";

const TeacherModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture, // Pass the current picture when editing
}) => {
  const [newPicture, setNewPicture] = useState(null);
  const [isMarried, setIsMarried] = useState(false);

  useEffect(() => {
    // Reset the new picture state when the modal opens
    if (isEdit) {
      setNewPicture(currentPicture); // Set initial picture if editing
    } else {
      setNewPicture(null); // Reset for adding a new teacher
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPicture(imageUrl); // Update local state with the new picture URL
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              reset(); // Reset form when closing modal
              setIsModalOpen(false);
            }}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <span>
              <X />
            </span>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Teacher" : "Add Teacher"}
        </h2>

        {/* If in edit mode and there's a current picture, display it */}
        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture} // Use new picture if available
              alt="Teacher Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <h1 className="text-lg font-bold">Personal Details</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("username")}
                required
              />
            </div>
            <div>
              <label className="block">Gender</label>
              <select
                className="w-full p-2 border rounded"
                {...register("gender")}
                required
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>
              <label className="block">Email</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                {...register("email")}
                required
              />
            </div>
            <div>
              <label className="block">Phone</label>
              <input
                type="tel"
                className="w-full p-2 border rounded"
                {...register("phone")}
                required
              />
            </div>
            <div>
              <label className="block">Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("password")}
                required
              />
            </div>
            <div>
              <label className="block">Location</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("location")}
                required
              />
            </div>
            <div>
              <label className="block">DOB</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("dob")}
                required
              />
            </div>
            <div>
              <label className="block">Joining Date</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("joining_date")}
                required
              />
            </div>
            <div>
              <label className="block">Job Nature</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("job_nature")}
                required
              />
            </div>
            <div>
              <label className="block">Combinations</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("combinations")}
                required
              />
            </div>
            <div>
              <label className="block">CNIC</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("cnic")}
                required
              />
            </div>
            {/* New fields */}
            <div>
              <label className="block">Status</label>
              <select
                className="w-full p-2 border rounded"
                {...register("status")}
                required
              >
                <option value="Active">Active</option>
                <option value="Resigned">Resigned</option>
                <option value="Unpaid Leaves">Unpaid Leaves</option>
                <option value="Promoted">Promoted</option>
              </select>
            </div>
            <div>
              <label className="block">Marital Status</label>
              <select
                className="w-full p-2 border rounded"
                {...register("marital_status")}
                required
                onChange={(e) => setIsMarried(e.target.value === "Married")}
              >
                <option value="Single">Single</option>
                <option value="Married">Married</option>
              </select>
            </div>

            {isMarried && (
              <>
                <div>
                  <label className="block">Spouse Name</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("spouse_name")}
                  />
                </div>
                <div>
                  <label className="block">Spouse CNIC</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("spouse_cnic")}
                  />
                </div>
                <div>
                  <label className="block">Kids Details (If any)</label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    {...register("kids_details")}
                  />
                </div>
              </>
            )}

            <div>
            <label className="block">
                {isEdit ? "Upload New Picture" : "Upload Picture"}
              </label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("picture")}
                required={!isEdit} // Only required in add mode
                onChange={handlePictureChange} // Update picture on change
              />
            </div>
          </div>
          <h1 className="mt-2 text-lg font-bold">
            Courses & Classes Academic Performance
          </h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Course Level</label>
              <select
                className="w-full p-2 border rounded"
                {...register("course_level")}
                required
              >
                <option value="College">College</option>
                <option value="High">High</option>
                <option value="Middle">Middle</option>
                <option value="Early">Early</option>
              </select>
            </div>
            <div>
              <label className="block">Class</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("class")}
                required
              />
            </div>
            <div>
              <label className="block">Section</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("section")}
                required
              />
            </div>
            <div>
              <label className="block">Subject</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("subject")}
                required
              />
            </div>
            <div>
              <label className="block">1st Sems Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("first_sems_res")}
                required
              />
            </div>
            <div>
              <label className="block">2nd Sems Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("second_sems_res")}
                required
              />
            </div>
            <div>
              <label className="block">3rd Sems Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("third_sems_res")}
                required
              />
            </div>
            <div>
              <label className="block">Overall Annual Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("overall_annual_res")}
                required
              />
            </div>
          </div>
          <h1 className="mt-2 text-lg font-bold">
            Courses & Classes Behavior Performance
          </h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Attendance%</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("attendance")}
                required
              />
            </div>
            <div>
              <label className="block">Teachers Observation</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("teachers_observation")}
                required
              />
            </div>
          </div>
          <h1 className="mt-2 text-lg font-bold">Academic & Experience</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Academic</label>
              <select
                className="w-full p-2 border rounded"
                {...register("academic")}
                required
              >
                <option value="Degree">Degree</option>
                <option value="Diploma">Diploma</option>
              </select>
            </div>
            <div>
              <label className="block">Institute</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("institute")}
                required
              />
            </div>
            <div>
              <label className="block">Completed In</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("completed_in")}
                required
              />
            </div>
            <div>
              <label className="block">Major Subject</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("major_subject")}
                required
              />
            </div>
            <div>
              <label className="block">CGPA</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("cgpa")}
                required
              />
            </div>
          </div>
          <h1 className="mt-2 text-lg font-bold">Professional Experience</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Organization Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("organization_name")}
                required
              />
            </div>
            <div>
              <label className="block">Designation</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("designation")}
                required
              />
            </div>
            <div>
              <label className="block">Started date</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("started_date")}
                required
              />
            </div>
            <div>
              <label className="block">Ended Date</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("ended_date")}
                required
              />
            </div>
            <div>
              <label className="block">Last Salary Drawn</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("last_salary")}
                required
              />
            </div>
            <div>
              <label className="block">Main Responsiblities</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("main_res")}
                required
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-[#241763] text-white rounded"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeacherModal;
