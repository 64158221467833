import React from "react";
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import "react-toastify/dist/ReactToastify.css";

const StudentModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture,
}) => {
  const [newPicture, setNewPicture] = useState(null);

  useEffect(() => {
    // Reset the new picture state when the modal opens
    if (isEdit) {
      setNewPicture(currentPicture); // Set initial picture if editing
    } else {
      setNewPicture(null); // Reset for adding a new teacher
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPicture(imageUrl); // Update local state with the new picture URL
    }
  };
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-red-500 text-white p-1 ml-4 rounded"
            onClick={() => {
              setIsModalOpen(false);
              reset();
            }}
          >
            <span>
              <X />
            </span>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Student" : "Add Student"}
        </h2>
        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture} // Use new picture if available
              alt="Student Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <h1 className="text-lg font-bold">Personal Details</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                {...register("username")}
                placeholder="Name"
                className="w-full p-2 border rounded"
                required
              />
            </div>

            <div>
              <label className="block">Email</label>
              <input
                {...register("email")}
                placeholder="Email"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Phone</label>
              <input
                {...register("phone")}
                placeholder="Phone"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Password</label>
              <input
                {...register("password")}
                type="password"
                placeholder="Password"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Location</label>
              <input
                {...register("location")}
                placeholder="Location"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">DOB</label>
              <input
                {...register("dob")}
                type="date"
                placeholder="Date of Birth"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Gender</label>
              <select
                {...register("gender")}
                className="w-full p-2 border rounded"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div>
              <label className="block">Standard</label>
              <input
                {...register("standard")}
                placeholder="Standard"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Section</label>
              <input
                {...register("section")}
                placeholder="Section"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
              <label className="block">Admission Date</label>
              <input
                {...register("admission_date")}
                type="date"
                placeholder="Admission Date"
                className="w-full p-2 border rounded"
              />
            </div>

            <div>
            <label className="block">
                {isEdit ? "Upload New Picture" : "Upload Picture"}
              </label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("picture")}
                required={!isEdit} // Only required in add mode
                onChange={handlePictureChange} // Update picture on change
              />
            </div>
          </div>

          <h1 className="mt-2 text-lg font-bold">Parent Details</h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Father Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("father_name")}
                required
              />
            </div>
            <div>
              <label className="block">CNIC</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("father_cnic")}
                required
              />
            </div>
            <div>
              <label className="block">Contact</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("father_contact")}
                required
              />
            </div>
            <div>
              <label className="block">Profession</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("father_profession")}
                required
              />
            </div>
            <div>
              <label className="block">Mother Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("mother_name")}
                required
              />
            </div>
            <div>
              <label className="block">CNIC</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("mother_cnic")}
                required
              />
            </div>
            <div>
              <label className="block">Contact</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("mother_contact")}
                required
              />
            </div>
            <div>
              <label className="block">Profession</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("mother_profession")}
                required
              />
            </div>
          </div>

          <h1 className="mt-2 text-lg font-bold">Academic Performance</h1>
          <h2 className="mt-4 text-md font-bold">Grade Book</h2>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">School</label>
              <select
                {...register("school")}
                className="w-full p-2 border rounded"
              >
                <option value="high">High</option>
                <option value="mid">Mid</option>
                <option value="early-year">Early Year</option>
              </select>
            </div>
            <div>
              <label className="block">Classes</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("classes")}
                required
              />
            </div>
            <div>
              <label className="block">Quizes</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("quizes")}
                required
              />
            </div>
            <div>
              <label className="block">Assignment Completion</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("assignment_completion")}
                required
              />
            </div>
            <div>
              <label className="block">Mid Term Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("midterm_res")}
                required
              />
            </div>
            <div>
              <label className="block">Final Term Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("finalterm_res")}
                required
              />
            </div>
            <div>
              <label className="block">Overall Annual Result</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("overall_annual_res")}
                required
              />
            </div>

            <br />
            <h2 className="mt-4 text-md font-bold">Fee & Dues Details</h2>
            <br />

            <div>
              <label className="block">Monthly Fee</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("monthly_fee")}
                required
              />
            </div>

            <div>
              <label className="block">Any Special Discount</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("special_disc")}
                required
              />
            </div>

            <div>
              <label className="block">Sibling Discount</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("sibling_disc")}
                required
              />
            </div>

            <div>
              <label className="block">Employee Kids Discount</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("emp_kids_disc")}
                required
              />
            </div>

            <div>
              <label className="block">LMS Fee</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("lms_fee")}
                required
              />
            </div>

            <div>
              <label className="block">Extra Curricular Fee</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("extra_curricular_fee")}
                required
              />
            </div>
          </div>

          <h1 className="mt-2 text-lg font-bold">
            Behavior & Attendance Performance
          </h1>
          <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
            <div>
              <label className="block">Attendance%</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("attendance")}
                required
              />
            </div>
            <div>
              <label className="block">Teachers Observation</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("teachers_observation")}
                required
              />
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-[#241763] text-white p-2 rounded"
            >
              {isEdit ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StudentModal;