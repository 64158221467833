import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching teachers
export const fetchTeachers = createAsyncThunk('teachers/fetchTeachers', async () => {
  const response = await axios.get('/dummyData.json');
  return response.data; // Return the response data to the fulfilled action
});

const initialState = {
  teachers: [],
  loading: false,
  error: null,
};

const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    addTeacher: (state, action) => {
      state.teachers.push(action.payload);
    },
    updateTeacher: (state, action) => {
      const index = state.teachers.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.teachers[index] = action.payload;
      }
    },
    deleteTeacher: (state, action) => {
      state.teachers = state.teachers.filter(teacher => teacher.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeachers.pending, (state) => {
        state.loading = true; // Set loading to true while fetching
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false after fetching
        state.teachers = action.payload; // Set the teachers from the API response
      })
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.loading = false; // Set loading to false if there's an error
        state.error = action.error.message; // Capture error message
      });
  },
});

export const { addTeacher, updateTeacher, deleteTeacher } = teachersSlice.actions;

export default teachersSlice.reducer;
